import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

const Filter = ({
  filters = [],
  searchParams,
  setSearchParams,
  filterDelete,
  setAreUSure,
  changeObj,
}) => {
  const { id } = useParams();

  //
  const renderItem = (filter) => {
    switch (filter.type) {
      case "select":
        return (
          <div className="col-lg-4">
            <label>{filter.label}</label>
            <select
              className="form-control"
              defaultValue={searchParams.get(filter.key) || ""}
              onChange={({ target: { value } }) => {
                if (value == "all") {
                  filterDelete(filter.key);
                } else {
                  changeObj([filter.key], value);
                }
              }}>
              <option value={""}>همه</option>
              {filter.options.map((op) => {
                return <option value={op.key}>{op.label}</option>;
              })}
            </select>
          </div>
        );
      case "input":
        if (filter.noShow) {
          return <></>;
        }
        return (
          <div className="col-lg-4">
            <label>{filter.label}</label>
            <input
              className="form-control"
              defaultValue={searchParams.get(filter.key) || ""}
              onChange={({ target: { value } }) => {
                if (value == "all") {
                  filterDelete(filter.key);
                } else {
                  changeObj([filter.key], value);
                }
              }}></input>
          </div>
        );
        break;
      case "btn":
        if (searchParams.get(filter.statement.key) == filter.statement.value) {
          return (
            <div className="col-lg-6">
              <button
                onClick={() => {
                  setAreUSure({
                    status: true,
                    type: "post",
                    action: { route: filter.route, data: {} },
                  });
                }}
                className="btn btn-danger "
                style={{ whiteSpace: "nowrap" }}>
                {filter.text}
              </button>
            </div>
          );
        }
      case "link":
        return (
          <div className="col-lg-4">
            <Link
              to={filter.url + (filter.withId ? id : "")}
              className="btn btn-danger"
              style={{ whiteSpace: "nowrap", ...filter?.style }}
            >
              {filter.text}
            </Link>
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div className="row" style={{}}>
      {filters.map((filter) => {
        return renderItem(filter);
      })}
    </div>
  );
};

export default Filter;
