import React from "react";
import Table from "../Table";
import CardTable from "./Components/CardTable";
import Plot from "./Components/Plots";
import Clock from "./Components/Clock";
import { useDashboard } from "./Hooks";
import PlotCard from "./Components/PlotCard";
import Results from "./Components/Results";
import { renderPrice } from "../../Utils/functions";
const Admin = () => {
  const { data, loading } = useDashboard({ api: { route: "admin/dashboard" } });
  if (loading) {
    return <>Loading ...</>;
  }
  console.log(data);
  return (
    <>
      <div className="row mb-2">
        <div className="col-lg-3 d-flex justify-content-center">
          <Clock size={200} />
        </div>
        <div className="col-lg-9">
          <CardTable data={data} />
        </div>
        <div className="row g-3 mb-3">
          <PlotCard
            title={"کل درآمد تا کنون"}
            unit={"تومان"}
            value={renderPrice(data?.total_income)}
            desc={"با توجه به فروش از ابتدا تا کنون محاسبه ‌شده است"}
          />
          <PlotCard
            title={"کل درآمد ماه"}
            unit={"تومان"}
            value={renderPrice(data?.total_month_income)}
            desc={"با توجه به فروش  ماه محاسبه ‌شده است"}
          />
          <PlotCard
            title={"کل درآمد هفته"}
            unit={"تومان"}
            value={renderPrice(data?.total_week_income)}
            desc={"با توجه به فروش هفته محاسبه ‌شده است"}
          />
          <PlotCard
            title={"کل درآمد روز "}
            unit={"تومان"}
            value={renderPrice(data?.total_today_income)}
            desc={"با توجه به فروش از امروز محاسبه ‌شده است"}
          />
        </div>
        <div className="row mb-">
          <Plot label="درآمد ماهیانه" data={data.order_chart} />
        </div>
        <div className="row g-3 mb-3">
          <PlotCard
            title={"درصد پیشرفت فروش هفت روزه"}
            unit={"درصد"}
            value={data.Percentage_Two_Week}
            desc={
              "با توجه به فروش ۷ روز گذشته نسبت به ۱۴ روز گذشته محاسبه ‌شده است"
            }
          />
          <PlotCard
            title={"سفارشات منتظر بررسی"}
            unit={"عدد"}
            value={data.review_and_issue_count}
            desc={"سفارشاتی که طرح خود را ثبت کرده اند"}
          />
        </div>
      </div>
    </>
  );
};

export default Admin;
