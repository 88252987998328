import React from "react";
import { ToDateJalali } from "../../Utils/functions";
import { useOrderDetails, useSurface } from "./Hooks";

const Reports = ({ option }) => {
  const { loading, data, sendData, loadingBtn, user } = useSurface(option);
  const render = report_id => {
    if (user.role == "admin" || user.role == "sales_manager") {
      return (
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-danger mt-2"
            onClick={() => sendData(report_id)}
          >
            {loadingBtn == report_id ? "loading" : "حذف"}
          </button>
        </div>
      );
    }
    return <></>;
  };
  if (loading) {
    return <>Laoding</>;
  }
  return (
    <div className="card">
      <div className="card-header bg-light">
        <h5 className="mb-0">گزارشات </h5>
      </div>
      <div className="card-body fs--1 p-0">
        {data.length == 0 ? (
          <>
            <h6 className="text-center mt-3">گزارشی ثبت نشده</h6>
          </>
        ) : (
          data.map(ele => {
            return (
              <div
                className="border-bottom-0 notification rounded-0 border-x-0 border border-300"
                href="#!"
              >
                <div className="notification-avatar">
                  <div className="avatar avatar-xl me-3">
                    <div className="avatar-emoji rounded-circle">
                      <span role="img" aria-label="ایموجی">
                        💬
                      </span>
                    </div>
                  </div>
                </div>
                <div className="notification-body w-100">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>
                        <span>
                          {ele.type == "seller"
                            ? "کارشناس فروش"
                            : ele.type == "admin"
                            ? "مدیر اصلی"
                            : "مدیر فروش"}
                        </span>
                        <br />
                        <span className="mt-2">{ele.seller.fullname}</span>
                      </p>
                    </div>
                    <div>
                      <span className="notification-time">
                        {ToDateJalali(ele.created_at)}
                      </span>
                    </div>
                  </div>

                  <p className="mb-1">{ele.report}</p>
                  <p className="link link-success">
                    {ele.file && <a href={ele.file}>دانلود فایل پیوست</a>}
                  </p>
                  {render(ele.id)}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Reports;
