import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import qr_code from "./options.json";
import "./index.css";
export default function DownloadQrCode({ url }) {
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);
  const ref2 = useRef(null);

  const qrCode = new QRCodeStyling({
    ...qr_code,
    width: 1000,
    height: 1000,
  });
  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
    // qrCode?._canvas?.width = "16.66667%";
  }, [url]);
  const onUrlChange = event => {
    event.preventDefault();
    // setUrl(event.target.value);
  };
  useEffect(() => {}, [url]);

  const onExtensionChange = event => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt,
    });
  };

  return (
    <>
      <div className="qr-code-wrapper">
        <div
          style={{
            width: "500px",
            height: "500px",
            objectFit: "contain",
            borderRadius: "5px",
          }}
          ref={ref}
        />
        <button className="btn btn-danger mt-2" onClick={onDownloadClick}>
          download qrcode
        </button>
      </div>
    </>
  );
}

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20,
  },
};
