import moment from "jalali-moment";
import packageJson from "../../package.json";

export const timeSince = (date) => {
  if (date) {
    let data = new Date(date);
    let seconds = Math.floor((new Date() - data) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " سال پیش";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " ماه پیش";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " روز پیش";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " ساعت پیش";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " دقیقه پیش";
    }
    return "اخیرا";
  } else {
    return "اخیرا";
  }
};
export const cutText = (txt = "dwafawf", length = 20) => {
  if (txt) {
    return `${txt?.slice(0, length)} ...`;
  }
};
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};
export const ConvertDate = (date) => {
  if (date) {
    return moment.from(date, "fa", "YYYY/MM/DD").format("YYYY/MM/DD");
  } else {
    return null;
  }
};
export const sortSettings = (arr = []) => {
  let obj = {};
  arr.map((item) => {
    obj = { ...obj, [item.title]: item.value };
  });
  return obj;
};
export const ToDateJalali = (date) => {
  if (date) {
    return moment(date).locale("fa").format("YYYY/MM/DD");
  } else {
    return moment().locale("fa").format("YYYY/MM/DD");
  }
};
export const ToTimeJalali = (date) => {
  if (date) {
    return moment(date).locale("fa").format(" HH:mm:ss - YYYY/MM/DD");
  } else {
    return null;
  }
};
export const convertKeys = (arr = [], order = {}) => {
  let keysOrder = Object.keys(order);
  const renderObj = (obj) => {
    return keysOrder.reduce((prev, currentValue) => {
      return {
        ...prev,
        [order[currentValue]]: obj[currentValue],
      };
    }, {});
  };
  return arr.map((element) => renderObj(element));
};
export const ToDateDayMonthJalali = (date) => {
  if (date) {
    return moment(date).locale("fa").format("MM/DD");
  } else {
    return null;
  }
};
export const caching = () => {
  let version = localStorage.getItem("version");
  if (version != packageJson.version) {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }

    localStorage.clear();
    localStorage.setItem("version", packageJson.version);
  }
};
export const renderStatus = (type) => {
  switch (type) {
    case "wait_to_pay":
      return {
        label: "منتظر پرداخت",
        status: "wait",
      };
    case "success":
      return {
        status: "success",
        label: "موفق",
      };
    case "error":
      return {
        status: "error",
        label: "ناموفق",
      };
    case "abandoned":
      return {
        status: "wait",
        label: "رها شده",
      };
    case "receiving_info":
      return {
        label: "دریافت اطلاعات",
      };
    case "review_and_issue":
      return {
        label: "بررسی و صدور",
      };
    case "ready_to_send":
      return {
        label: "آماده ارسال",
      };
    case "posted":
      return {
        label: "ارسال شده",
      };
    case "returned":
      return {
        label: "بازگشت از پست",
      };
    case "active":
      return {
        label: "فعال",
      };
    case "inactive":
      return {
        label: "غیر فعال",
      };
    case "blocked":
      return {
        label: "مسدود",
      };

    default:
      break;
  }
};
export const saveAs = (blob, fileName) => {
  var elem = window.document.createElement("a");
  elem.href = blob;
  elem.download = fileName;
  elem.style = "display:none;";
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === "function") {
    elem.click();
  } else {
    elem.target = "_blank";
    elem.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};
export const p2e = (s) => s.replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
export const isJson = (json) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};
export const renderPrice = (x = "", d = ",") => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, d);
};
export const renderPriceDash = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "/");
};
