import React, { useEffect, useState } from "react";
import { post } from "../../../../Server";
import { p2e, ToDateJalali } from "../../../../Utils/functions";
import Form from "../../../Form";
import CustomDatePicker from "../../../Form/Components/DatePicker";
import FileUploader from "../../../Form/Components/FileUploader";
import MultiSelect from "../../../Form/Components/MultiSelect";

const ModalContent = ({ data, setAreUSure, LoadingDelete, deleteRow }) => {
  useEffect(() => {
    setForm({ experters: [] });
  }, [LoadingDelete]);

  const [form, setForm] = useState({ experters: [] });
  const handleClose = () => setAreUSure({ ...data, status: false });
  switch (data.type) {
    case "delete":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">آیا از حذف این داده مطمئن هستید</div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  خیر
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "بله"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "toggle":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">آیا مطمئن هستید</div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  خیر
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "بله"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case "updateConfirm":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">
                  شما در حال تایید این درخواست هستید ، آیا مطمئن هستید؟
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  خیر
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "بله"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case "toggleOrder":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">{data.action.label}</div>
              </div>
              <div className="p-2">
                <select
                  className="form-control"
                  defaultValue={data?.data?.rel?.status}
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, status: value });
                  }}>
                  {data.action.options.map((option) => {
                    return <option value={option.key}>{option.label}</option>;
                  })}
                </select>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    deleteRow(data, { ...form, order_id: data?.data?.id })
                  }>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "select":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">{data.action.label}</div>
              </div>
              <div className="p-2">
                <select
                  className="form-control"
                  defaultValue={data.data[data.action.objectKey]}
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, [data.action.objectKey]: value });
                  }}>
                  {data.action.options.map((option) => {
                    return <option value={option.key}>{option.label}</option>;
                  })}
                </select>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data, form)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "add":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">{data.action.label}</div>
              </div>
              <div className="p-2">
                <textarea
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, [data.action.objectKey]: value });
                  }}></textarea>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data, form)}
                  disabled={!form[data.action.objectKey]}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "multiSelect":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">
                  کارشناس های مورد نظر خود را انتخاب کنید
                </div>
              </div>
              <div className="p-2">
                <SelectSaleexperts form={form} setForm={setForm} />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="flexCheckIndeterminate"
                    type="checkbox"
                    defaultValue
                    onChange={() => {
                      setForm({ ...form, all: !form.all });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckIndeterminate">
                    تخصیص به همه کارشناسان به صورت رندوم
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    deleteRow(
                      {
                        ...data,
                        action: {
                          ...data.action,
                          route: form.all
                            ? data.action.subRoute
                            : data.action.route,
                        },
                      },
                      { ...form, ...data.inintal }
                    )
                  }
                // disabled={!form[data.action.objectKey]}
                >
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "gift":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">ثبت یا ویرایش تخفیف</div>
              </div>
              <div className="p-2">
                <CustomDatePicker
                  element={{
                    label: "تاریخ انقضا",
                    single: true,
                    key: "exp_date",
                  }}
                  form={form}
                  setForm={setForm}
                />
                <div className="form-group my-2">
                  <label htmlFor="exampleInputName1">قیمت جدید</label>
                  <input
                    onChange={({ target: { value } }) => {
                      setForm({ ...form, second_price: value });
                    }}
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder={"200000"}
                    defaultValue={data?.data?.second_price?.price}
                  />
                </div>
                {data?.data?.second_price?.price && (
                  <div className="alert alert-danger d-flex justify-content-between">
                    <p className="m-0"> قیمت جدید </p>
                    <p className="m-0">
                      {" "}
                      {data?.data?.second_price?.price} -{" "}
                      {ToDateJalali(data?.data?.second_price?.exp)}
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    deleteRow(
                      {
                        ...data,
                        operator: "new",
                      },
                      { ...form, operator: "new" }
                    )
                  }
                // disabled={!form[data.action.objectKey]}
                >
                  {LoadingDelete == "new" ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت "
                  )}
                </button>
                {data?.data?.second_price?.price && (
                  <>
                    {" "}
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        deleteRow(
                          {
                            ...data,
                            operator: "new",
                          },
                          { ...form, operator: "cancel" }
                        )
                      }
                    // disabled={!form[data.action.objectKey]}
                    >
                      {LoadingDelete == "cancel" ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      ) : (
                        "حذف "
                      )}
                    </button>
                    <button
                      className="btn btn-warning"
                      onClick={() =>
                        deleteRow(
                          {
                            ...data,
                            operator: "new",
                          },
                          { ...form, operator: "edit" }
                        )
                      }
                    // disabled={!form[data.action.objectKey]}
                    >
                      {LoadingDelete == "edit" ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      ) : (
                        "ویرایش"
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case "reply":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">پاسخ نظر</div>
              </div>
              <div className="p-2">
                <div>
                  <p>{data?.data?.text}</p>
                </div>
                <div className="form-group my-2">
                  <label htmlFor="exampleInputName1">پاسخ شما</label>
                  <textarea
                    onChange={({ target: { value } }) => {
                      setForm({ ...form, text: value });
                    }}
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    rows={4}
                    placeholder={"..."}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    deleteRow(
                      {
                        ...data,
                      },
                      { ...form }
                    )
                  }
                // disabled={!form[data.action.objectKey]}
                >
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "report":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">ثبت گزارش</div>
              </div>
              <div className="p-2">
                <div>
                  <p>{data?.data?.text}</p>
                </div>
                <div className="form-group my-2">
                  <label htmlFor="exampleInputName1">متن گزارش </label>
                  <textarea
                    onChange={({ target: { value } }) => {
                      setForm({ ...form, report: value });
                    }}
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    rows={4}
                    placeholder={"..."}
                  />
                </div>
                <FileUploader
                  element={{
                    label: "",
                    key: "file",
                  }}
                  form={form}
                  setForm={setForm}
                />
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    deleteRow(
                      {
                        ...data,
                      },
                      { ...form }
                    )
                  }
                // disabled={!form[data.action.objectKey]}
                >
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "addFrame":
      return (
        <>
          <div
            className={`modal fade ${data.status && "show"} `}
            id="error-modal"
            tabIndex={-1}
            aria-modal="true"
            role="dialog"
            style={{
              backgroundColor: "rgba(0,0,0,0.6)",
              display: data.status ? "block" : "none",
            }}>
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              style={{ maxWidth: 500 }}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button
                    className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal"
                    aria-label="نزدیک"
                    onClick={handleClose}
                  />
                </div>
                <Form
                  options={{
                    modal: true,
                    show: true,
                    type: "form",
                    label: "ثبت سفارش دستی",
                    route: "/order/submit",
                    api: {
                      route: "/admin/order/new_manual",
                      needs: ["products", "faq_categories"],
                    },
                    sortInint: (state) => {
                      return { ...state };
                    },
                    initial: { product_id: data?.data?.product_id },
                    form: [
                      {
                        key: "typeCard",
                        label: "نوع طرح کارت",
                        type: "select",
                        exist: {
                          key: "product_id",
                          value: 1,
                        },
                        options: [
                          {
                            name: "طرح اختصاصی",
                            key: "custom",
                          },
                          {
                            name: "طرح پیش فرض",
                            key: "default",
                          },
                        ],
                        noNeed: true,
                      },
                      {
                        type: "toggle",
                        label: "نیاز به qrcode",
                        key: "qrcode",
                        exist: {
                          key: "product_id",
                          value: 3,
                        },
                        noNeed: true,
                      },
                      {
                        key: "qrcodePosition",
                        label: "مکان qrcode",
                        type: "select",
                        noNeed: true,
                        exist: {
                          key: "qrcode",
                          value: "true",
                        },
                        options: [
                          {
                            name: "پایین چپ",
                            key: "bottom-left",
                          },
                          {
                            name: "پایین راست",
                            key: "bottom-right",
                          },
                          {
                            key: "top-left",
                            name: "بالا چپ",
                          },
                          {
                            key: "top-right",
                            name: "بالا راست",
                          },
                        ],
                      },
                      {
                        key: "typeFrame",
                        label: "نوع طرح قاب",
                        noNeed: true,
                        type: "select",
                        exist: {
                          key: "product_id",
                          value: 3,
                        },
                        options: [
                          {
                            name: "طرح اختصاصی",
                            key: "custom",
                          },
                          {
                            name: "طرح پیشفرض",
                            key: "default",
                          },
                        ],
                      },
                      {
                        noNeed: true,
                        type: "selectImage",
                        url: "frame",
                        exist: {
                          key: "typeFrame",
                          value: "default",
                        },
                        key: "default",
                      },

                      {
                        noNeed: true,
                        key: "picture",
                        label: "عکس قاب",
                        type: "cropper",
                        aspect: 10 / 14.12,
                        exist: {
                          key: "typeFrame",
                          value: "custom",
                        },
                      },
                      {
                        noNeed: true,
                        key: "persian_text",
                        label: "متن فارسی",
                        exist: {
                          key: "typeCard",
                          value: "default",
                        },
                      },
                      {
                        noNeed: true,
                        key: "english_text",
                        label: "متن انگلیسی",
                        exist: {
                          key: "typeCard",
                          value: "default",
                        },
                      },
                      {
                        noNeed: true,
                        key: "picture",
                        label: "لوگو کارت",
                        type: "cropper",
                        aspect: 1 / 1,
                        exist: {
                          key: "typeCard",
                          value: "default",
                        },
                      },
                      {
                        noNeed: true,
                        key: "front_image",
                        label: "روی کارت",
                        type: "cropper",
                        aspect: 8.5 / 5.5,
                        exist: {
                          key: "typeCard",
                          value: "custom",
                        },
                      },
                      {
                        noNeed: true,
                        key: "back_image",
                        label: "پشت کارت",
                        type: "cropper",
                        aspect: 8.5 / 5.5,
                        exist: {
                          key: "typeCard",
                          value: "custom",
                        },
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </>
      );
    case "trackingPost":
      return (
        <>
          <div
            className={`modal fade ${data.status && "show"} `}
            id="error-modal"
            tabIndex={-1}
            aria-modal="true"
            role="dialog"
            style={{
              backgroundColor: "rgba(0,0,0,0.6)",
              display: data.status ? "block" : "none",
            }}>
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              style={{ maxWidth: 500 }}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button
                    className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal"
                    aria-label="نزدیک"
                    onClick={handleClose}
                  />
                </div>
                <div className="modal-body p-0">
                  <div className="p-3">ثبت کد پستی</div>
                </div>
                <div className="p-2">
                  <div>
                    <p>{data?.data?.text}</p>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="exampleInputName1">کد پستی </label>
                    <input
                      onChange={({ target: { value } }) => {
                        setForm({ ...form, post_tracking_code: p2e(value) });
                      }}
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      rows={4}
                      placeholder={"کد پستی"}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" onClick={handleClose}>
                    بستن
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      deleteRow(
                        {
                          ...data,
                        },
                        { ...form, order_id: data?.data?.id }
                      )
                    }
                    disabled={!form.post_tracking_code}>
                    {LoadingDelete ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    ) : (
                      "ثبت"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    case "post":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">آیا مطمئن هستید</div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  خیر
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "بله"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      break;
  }
  return;
};
const SelectSaleexperts = ({ form, setForm }) => {
  const [loadingNeed, setLoadingNeed] = useState(true);
  const [data, setData] = useState({ saleexperts: [] });
  console.log("sa", setForm);
  const getNesdata = async () => {
    setLoadingNeed(false);
    const result = await post("/required/data", { keys: ["sellers"] });
    setData(result.data.data);
    setLoadingNeed(false);
  };
  useEffect(() => {
    getNesdata();
  }, []);
  return (
    <MultiSelect
      loading={loadingNeed}
      element={{
        label: "کارشناسان",
        key: "sellers",
      }}
      handleValue={() => { }}
      handleChange={(e, value) => {
        setForm({ ...form, sellers: value.map((ele) => ele.value) });
      }}
      data={data["sellers"]}
      disible={form.all}
      form={form}
      setForm={setForm}
    />
  );
};

export default ModalContent;
