import React from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

const Layout = ({ children }) => {
  return (
    <main className="main" id="top">
      <div className="container" data-layout="container">
        <Sidebar />
        <div className="content">
          <Navbar />
          {children}
        </div>
      </div>
      <footer
        className="position-absolute w-100"
        style={{
          bottom: "2vh",
        }}
      >
        <p className="text-center">
          توسعه و پیاده سازی تخصصی در تیم طراحی و توسعه آکو | نسخه 1.0.0
        </p>
      </footer>
    </main>
  );
};

export default Layout;
