import React from "react";
import { useMainContext } from "../../Context";
import Admin from "./Admin";
import Operator from "./Operator";
import Editor from "./Editor";
import SalesManager from "./SalesManager";
import Seller from "./Seller";
import SeoManager from "./SeoManager";
const Dashboard = () => {
  const {
    state: { user },
  } = useMainContext();
  switch (user.role) {
    case "admin":
      return <Admin />;
    case "editor":
      return <Editor />;
    case "operator":
      return <Operator />;
    case "sales_manager":
      return <SalesManager />;
    case "seller":
      return <Seller />;
    case "seo_manager":
      return <SeoManager />;
    default:
      break;
  }
};

export default Dashboard;
