import React from "react";
import { useLocation } from "react-router-dom";
import { ToDateJalali, ToTimeJalali } from "../../Utils/functions";

const TicketDetails = () => {
  const {
    state: { row },
  } = useLocation();

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md d-flex">
              <div className="avatar avatar-2xl">
                <img
                  className="rounded-circle"
                  src="../../assets/img/team/avatar.png"
                  alt
                />
              </div>
              <div className="flex-1 ms-2">
                <h5 className="mb-0">{row.title}</h5>
                <a className="text-800 fs--1" href="#!">
                  <span className="fw-semi-bold">{row.fullname} </span>
                  <span className="ms-1 text-500">{row.email}</span>
                </a>
              </div>
            </div>
            <div className="col-md-auto ms-auto d-flex align-items-center ps-6 ps-md-3">
              <small>{ToTimeJalali(row.created_at)}</small>
              <span className="fas fa-star text-warning fs--1 ms-2" />
            </div>
          </div>
        </div>
        <div className="card-body bg-light">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="card shadow-none mb-3">
                <div className="card-body">
                  <p>{row.description}</p>
                  <div className="text-center">
                    <a
                      href={"tel:" + row.mobile}
                      className="btn btn-success btn-lg my-3"
                      type="button"
                    >
                      تماس - {row.mobile}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
