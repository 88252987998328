import React, { useEffect } from "react";

const Chat = ({ form, getChat, loading, chat, setForm, submitAnswer }) => {
  if (loading.chat) {
    return (
      <div className="tab-content card-chat-content">
        <div
          className="tab-pane card-chat-pane active"
          id="chat-0"
          role="tabpanel"
          aria-labelledby="chat-link-0"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    );
  }
  return (
    <div className="tab-content card-chat-content">
      <div
        className="tab-pane card-chat-pane active"
        id="chat-0"
        role="tabpanel"
        aria-labelledby="chat-link-0"
      >
        <div className="chat-content-header">
          <div className="row flex-between-center">
            <div className="col-6 col-sm-8 d-flex align-items-center">
              <a
                className="pe-3 text-700 d-md-none contacts-list-show"
                href="#!"
              >
                <div className="fas fa-chevron-left" />
              </a>
              <div className="min-w-0">
                <h5 className="mb-0 text-truncate fs-0">
                  {chat.user?.firstname} {chat.user?.lastname}
                </h5>
                <div className="fs--2 text-400">فعال در چت</div>
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-sm btn-falcon-primary me-2"
                type="button"
                data-index={0}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="تماس را شروع کنید"
              >
                <span className="fas fa-phone" />
              </button>
              <button
                className="btn btn-sm btn-falcon-primary me-2"
                type="button"
                data-index={0}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="تماس تصویری را شروع کنید"
              >
                <span className="fas fa-video" />
              </button>
              <button
                className="btn btn-sm btn-falcon-primary btn-info"
                type="button"
                data-index={0}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="اطلاعات مکالمه"
              >
                <span className="fas fa-info" />
              </button>
            </div>
          </div>
        </div>
        <div className="chat-content-body" style={{ display: "inherit" }}>
          <div className="conversation-info" data-index={0}>
            <div className="h-100 overflow-auto scrollbar">
              <div className="d-flex position-relative align-items-center p-3 border-bottom">
                <div className="avatar avatar-xl status-online">
                  <img
                    className="rounded-circle"
                    src="../assets/img/team/2.jpg"
                    alt
                  />
                </div>
                <div className="flex-1 ms-2 d-flex flex-between-center">
                  <h6 className="mb-0">
                    <a
                      className="text-decoration-none stretched-link text-700"
                      href="../pages/user/profile.html"
                    >
                      {chat.user?.firstname} {chat.user?.lastname}
                    </a>
                  </h6>
                  <div className="dropdown z-index-1">
                    <button
                      className="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                      type="button"
                      id="profile-dropdown-0"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="fas fa-cog" />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end border py-2"
                      aria-labelledby="profile-dropdown-0"
                    >
                      <a className="dropdown-item" href="#!">
                        بی صدا
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="#!">
                        بایگانی
                      </a>
                      <a className="dropdown-item text-danger" href="#!">
                        حذف
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-3 pt-2">
                <div className="nav flex-column font-sans-serif fw-medium">
                  <a
                    className="nav-link d-flex align-items-center py-1 px-0 text-600"
                    href="#!"
                  >
                    <span className="conversation-info-icon">
                      <span
                        className="fas fa-search me-1"
                        data-fa-transform="shrink-1 down-3"
                      />
                    </span>
                    جستجو در مکالمه
                  </a>
                  <a
                    className="nav-link d-flex align-items-center py-1 px-0 text-600"
                    href="#!"
                  >
                    <span className="conversation-info-icon">
                      <span
                        className="fas fa-pencil-alt me-1"
                        data-fa-transform="shrink-1"
                      />
                    </span>
                    ویرایش نام مستعار
                  </a>
                  <a
                    className="nav-link d-flex align-items-center py-1 px-0 text-600"
                    href="#!"
                  >
                    <span className="conversation-info-icon">
                      <span
                        className="fas fa-palette me-1"
                        data-fa-transform="shrink-1"
                      />
                    </span>
                    <span>تغییر رنگ</span>
                  </a>
                  <a
                    className="nav-link d-flex align-items-center py-1 px-0 text-600"
                    href="#!"
                  >
                    <span className="conversation-info-icon">
                      <span
                        className="fas fa-thumbs-up me-1"
                        data-fa-transform="shrink-1"
                      />
                    </span>
                    ایموجی را تغییر دهید
                  </a>
                  <a
                    className="nav-link d-flex align-items-center py-1 px-0 text-600"
                    href="#!"
                  >
                    <span className="conversation-info-icon">
                      <span
                        className="fas fa-bell me-1"
                        data-fa-transform="shrink-1"
                      />
                    </span>
                    اطلاعیه
                  </a>
                </div>
              </div>
              <hr className="my-2" />
              <div className="px-3" id="others-info-0">
                <div className="title" id="shared-media-title-0">
                  <a
                    className="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                    href="#shared-media-0"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="shared-media-0"
                  >
                    رسانه مشترک
                  </a>
                </div>
                <div
                  className="collapse"
                  id="shared-media-0"
                  aria-labelledby="shared-media-title-0"
                  data-parent="#others-info-0"
                >
                  <div className="row mx-n1">
                    <div className="col-6 col-md-4 px-1">
                      <a
                        href="../assets/img/chat/1.jpg"
                        data-gallery="images-1"
                      >
                        <img
                          className="img-fluid rounded-1 mb-2"
                          src="../assets/img/chat/1.jpg"
                          alt
                        />
                      </a>
                    </div>
                    <div className="col-6 col-md-4 px-1">
                      <a
                        href="../assets/img/chat/2.jpg"
                        data-gallery="images-1"
                      >
                        <img
                          className="img-fluid rounded-1 mb-2"
                          src="../assets/img/chat/2.jpg"
                          alt
                        />
                      </a>
                    </div>
                    <div className="col-6 col-md-4 px-1">
                      {" "}
                      <a
                        href="../assets/img/chat/3.jpg"
                        data-gallery="images-1"
                      >
                        <img
                          className="img-fluid rounded-1 mb-2"
                          src="../assets/img/chat/3.jpg"
                          alt
                        />
                      </a>
                    </div>
                    <div className="col-6 col-md-4 px-1">
                      {" "}
                      <a
                        href="../assets/img/chat/4.jpg"
                        data-gallery="images-1"
                      >
                        <img
                          className="img-fluid rounded-1 mb-2"
                          src="../assets/img/chat/4.jpg"
                          alt
                        />
                      </a>
                    </div>
                    <div className="col-6 col-md-4 px-1">
                      {" "}
                      <a
                        href="../assets/img/chat/5.jpg"
                        data-gallery="images-1"
                      >
                        <img
                          className="img-fluid rounded-1 mb-2"
                          src="../assets/img/chat/5.jpg"
                          alt
                        />
                      </a>
                    </div>
                    <div className="col-6 col-md-4 px-1">
                      {" "}
                      <a
                        href="../assets/img/chat/6.jpg"
                        data-gallery="images-1"
                      >
                        <img
                          className="img-fluid rounded-1 mb-2"
                          src="../assets/img/chat/6.jpg"
                          alt
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-content-scroll-area scrollbar">
            <div className="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
              <div className="avatar avatar-2xl status-online me-3">
                <img
                  className="rounded-circle"
                  src="../assets/img/team/2.jpg"
                  alt
                />
              </div>
              <div className="flex-1">
                <h6 className="mb-0">
                  <a
                    className="text-decoration-none stretched-link text-700"
                    href="../pages/user/profile.html"
                  >
                    {chat.user?.firstname} {chat.user?.lastname}
                  </a>
                </h6>
                <p className="mb-0">{chat.ticket}</p>
              </div>
            </div>
            <div className="text-center fs--2 text-500">
              <span>5 مه 2019، 11:54 صبح</span>
            </div>
            {chat.messages?.map((message, count) => {
              if (message.type == "answer") {
                return (
                  <div className="d-flex p-3">
                    <div className="flex-1 d-flex justify-content-end">
                      <div className="w-100 w-xxl-75">
                        <div className="hover-actions-trigger d-flex flex-end-center">
                          <ul className="hover-actions position-relative list-inline mb-0 text-400 me-2">
                            <li className="list-inline-item">
                              <a
                                className="chat-option"
                                href="#!"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="رو به جلو"
                              >
                                <svg
                                  className="svg-inline--fa fa-share fa-w-16"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="share"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  data-fa-i2svg
                                >
                                  <path
                                    fill="currentColor"
                                    d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"
                                  />
                                </svg>
                                {/* <span class="fas fa-share"></span> Font Awesome fontawesome.com */}
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                className="chat-option"
                                href="#!"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="بایگانی"
                              >
                                <svg
                                  className="svg-inline--fa fa-archive fa-w-16"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="archive"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  data-fa-i2svg
                                >
                                  <path
                                    fill="currentColor"
                                    d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z"
                                  />
                                </svg>
                                {/* <span class="fas fa-archive"></span> Font Awesome fontawesome.com */}
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                className="chat-option"
                                href="#!"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="ویرایش کنید"
                              >
                                <svg
                                  className="svg-inline--fa fa-edit fa-w-18"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="edit"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                  data-fa-i2svg
                                >
                                  <path
                                    fill="currentColor"
                                    d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                                  />
                                </svg>
                                {/* <span class="fas fa-edit"></span> Font Awesome fontawesome.com */}
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                className="chat-option"
                                href="#!"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="حذف"
                              >
                                <svg
                                  className="svg-inline--fa fa-trash-alt fa-w-14"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="trash-alt"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                  data-fa-i2svg
                                >
                                  <path
                                    fill="currentColor"
                                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                                  />
                                </svg>
                                {/* <span class="fas fa-trash-alt"></span> Font Awesome fontawesome.com */}
                              </a>
                            </li>
                          </ul>
                          <div className="bg-primary text-white p-2 rounded-2 chat-message light">
                            {message.message}
                          </div>
                        </div>
                        <div className="text-400 fs--2 text-end">
                          11:54 صبح
                          <svg
                            className="svg-inline--fa fa-check fa-w-16 ms-2 text-success"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="check"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg
                          >
                            <path
                              fill="currentColor"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            />
                          </svg>
                          {/* <span class="fas fa-check ms-2 text-success"></span> Font Awesome fontawesome.com */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="d-flex p-3" key={count}>
                  <div className="avatar avatar-l me-2">
                    <img
                      className="rounded-circle"
                      src="../assets/img/team/2.jpg"
                      alt
                    />
                  </div>
                  <div className="flex-1">
                    <div className="w-xxl-75">
                      <div className="hover-actions-trigger d-flex align-items-center">
                        <div className="chat-message bg-200 p-2 rounded-2">
                          {message.message}
                        </div>
                      </div>
                      <div className="text-400 fs--2">
                        <span>11:54 صبح</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="chat-editor-area">
        <div
          className="emojiarea-editor outline-none scrollbar"
          contentEditable="true"
        />
        <textarea
          className="w-100 form-control"
          type="text"
          id="chat-file-upload"
          value={form.message}
          onChange={({ target: { value } }) => {
            setForm({ message: value });
          }}
        />
        <label
          className="chat-file-upload cursor-pointer"
          htmlFor="chat-file-upload"
        >
          <span className="fas fa-paperclip" />
        </label>
        <div
          className="btn btn-link emoji-icon"
          data-emoji-button="data-emoji-button"
        >
          <span className="far fa-laugh-beam" />
        </div>
        <button
          disabled={!form.message}
          className="btn btn-sm btn-send"
          onClick={submitAnswer}
        >
          {loading.submit ? (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "ارسال"
          )}
        </button>
      </div>
    </div>
  );
};

export default Chat;
