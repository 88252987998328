import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { post } from "../../../Server";
import { supportChat, supportAnswer } from "../../../Utils/Apis";

export const useSupport = () => {
  const [chat, setChat] = useState({});
  const [form, setForm] = useState({ message: "" });
  const [loading, setLoading] = useState({
    chat: false,
    submit: false,
  });
  const { id } = useParams();

  // get chat
  const getChat = async () => {
    setLoading({ chat: true });
    const { data } = await post(supportChat, { ticket_id: id });
    setLoading({ chat: false });
    setChat(data);
  };
  // submit answer
  const submitAnswer = async () => {
    setLoading({ submit: true });
    const { data } = await post(
      supportAnswer,
      { ticket_id: id, ...form },
      true
    );
    setLoading({ submit: false });
    setForm({ message: "" });
    setChat({ ...chat, messages: data.messages });
  };
  useEffect(() => {
    if (!id) return;
    getChat();
  }, [id]);

  return { getChat, submitAnswer, loading, form, setForm, chat };
};
