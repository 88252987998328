import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../../../Context";

const CardTable = ({ data }) => {
  const { state } = useMainContext();
  return (
    <div className="col-12">
      <div className="card bg-transparent-50 overflow-hidden">
        <div className="card-header position-relative">
          <div className="position-relative z-index-2">
            <div className="text-center">
              <h3 className="text-primary mb-1">
                خوش آمدید،{" "}
                {state?.user?.user?.first_name +' '+ state?.user?.user?.last_name}!
              </h3>
            </div>
            <div className="d-flex py-3 justify-content-center">
              <div className="pe-3">
                <p className="text-600 fs--1 fw-medium">تعداد کل کاربران</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.user_count}
                </h4>
              </div>
              <div className="pe-3">
                <p className="text-600 fs--1 fw-medium">تعداد کل سفارشات</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.order_count}
                </h4>
              </div>
              <div className="ps-3">
                <p className="text-600 fs--1">تعداد کارت های فعال</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.active_card}
                </h4>
              </div>
              <div className="ps-3">
                <p className="text-600 fs--1">تعداد لینک های فعال</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.active_links}
                </h4>
              </div>
              <div className="ps-3">
                <p className="text-600 fs--1">تعداد قاب های فعال</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.active_frames}
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card-body p-0">
          <ul className="mb-0 list-unstyled">
            <li className="alert mb-0 rounded-0 py-3 px-card greetings-item border-top  border-0  alert-success">
              <div className="row flex-between-center">
                <div className="col">
                  <div className="d-flex">
                    <svg
                      className="svg-inline--fa fa-circle fa-w-16 mt-1 fs--2 text-primary"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      />
                    </svg>
                    <p className="fs--1 ps-2 mb-0">لیست محصولات</p>
                  </div>
                </div>
                <div className="col-auto d-flex align-items-center">
                  <Link
                    to={"/products/list"}
                    className="alert-link fs--1 fw-medium"
                    href="#!"
                  >
                      مشاهده تمام سفارشات 
                    <svg
                      className="svg-inline--fa fa-chevron-left fa-w-10 ms-1 fs--2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-left"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default CardTable;
