import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMainContext } from "../../../Context";

export const useOrderDetails = ({ api, key }) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [newAddress, setNewAddress] = useState({
    province_id: '',
    city_id: '',
    full_address: '',
    postal_code: '',
    type: 'self',
    full_name: '',
    mobile: ''
  });

  const [dataAddress, setDataAddress] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setloadingBtn] = useState(false);
  const [loadingNoteBtn, setloadingNoteBtn] = useState(false);
  const [loadingAddressBtn, setLoadingAddressBtn] = useState(false);
  const [loadingNewAddressBtn, setLoadingNewAddressBtn] = useState(false);
  const [addressModal, setAddressModal] = useState(false);



  const [note, setNote] = useState('');
  const {
    post,
    state: { user },
  } = useMainContext();
  useEffect(() => {
    getData();
  }, [loadingBtn]);
  const getData = async () => {
    setLoading(true);
    const { data } = await post(
      "/admin/order/details".replace("admin", user.role),
      { id }
    );
    if (Array.isArray(data)) {
      setData(data);
      setNote(data?.note);
    } else {
      setData(data);
      setNote(data?.note);
    }
    await getAddressData(data);
    setLoading(false);
  };

  const getAddressData = async (data) => {
    const { data: response } = await post(
      "/admin/order/address/list".replace("admin", user.role),
      { order_id: data.id }
    );
    if (Array.isArray(response)) {
      setDataAddress(response);
    } else {
      setDataAddress(response);
    }
  };
  const ready = async id => {
    setloadingBtn(true);
    const { data } = await post(
      "/admin/order/ready".replace("admin", user.role),
      { id }
    );
    console.log("salam", data);

    setloadingBtn(false);
  };

  const submitNote = async () => {
    setloadingNoteBtn(true);
    await post(
      "/admin/order/add_note".replace("admin", user.role),
      { note, order_id: data.id }, true
    );
    setloadingNoteBtn(false);
  };
  const submitAddress = async (id) => {
    setLoadingAddressBtn(true);
    await post(
      "/admin/order/address/set".replace("admin", user.role),
      { address_id: id, order_id: data.id }, true
    );
    getData();
    setLoadingAddressBtn(false);
  };
  const submitNewAddress = async () => {
    setLoadingNewAddressBtn(true);
    await post(
      "/admin/address_able".replace("admin", user.role),
      { ...newAddress, user_id: data.user_id }, true
    );
    await getAddressData(data);
    setAddressModal(false);
    setLoadingNewAddressBtn(false);
  };
  const sendData = async report_id => {
    setloadingBtn(report_id);
    await post("/crm/report_delete", { report_id });
    setloadingBtn(false);
  };

  return {
    loading,
    data,
    id,
    sendData,
    loadingBtn,
    user,
    ready,
    setData,
    submitNote,
    loadingNoteBtn, setNote, dataAddress, submitAddress, loadingAddressBtn, submitNewAddress, newAddress, loadingNewAddressBtn,
    setNewAddress, addressModal, setAddressModal
  };
};
export const useSurface = ({ api, key }) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setloadingBtn] = useState(false);
  const [reporter, setReporter] = useState({});
  const {
    post,
    state: { user },
  } = useMainContext();
  useEffect(() => {
    getData();
  }, [loadingBtn]);
  const getData = async () => {
    setLoading(true);
    const { data } = await post(api?.route.replace("seller", user.role), {
      [key]: id,
    });
    if (Array.isArray(data)) {
      setData(data);
    } else {
      setData(data);
      setReporter(data.experter);
    }
    setLoading(false);
  };

  const sendData = async report_id => {
    setloadingBtn(report_id);
    await post("/crm/report_delete", { report_id });
    setloadingBtn(false);
  };

  return {
    loading,
    data,
    id,
    sendData,
    loadingBtn,
    user,
    reporter,
  };
};
export const usePremium = ({ data, setData }) => {
  const [form, setForm] = useState({});
  const [loadingBtnPremium, setLoadingBtnPremium] = useState(false);
  const {
    post,
    state: { user },
  } = useMainContext();
  const toggle = async () => {
    setLoadingBtnPremium(true);
    await post("admin/product/setting".replace("admin", user.role), {
      product_id: data.rel_id,
      product_type: data.rel_type,
      lang: data.rel.lang == "fa" ? "en" : "fa",
    });
    setData({
      ...data,
      rel: { ...data.rel, lang: data.rel.lang == "fa" ? "en" : "fa" },
    });
    setLoadingBtnPremium(false);
  };
  return { toggle, loadingBtnPremium };
};
