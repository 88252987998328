import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import qr_code from "./options.json";
import "./index.css";
export default function QRCode({
  url,
  trigger,
  form,
  setForm,
  hidden = false,
  size = "a4",
  pos = "",
}) {
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [state, setState] = useState({ width: "100", height: "100" });
  const [state1, setState1] = useState({
    width: "100px",
    height: "100px",
    aspectRatio: "1/1",
  });

  const qrCode = new QRCodeStyling({
    ...qr_code,
    ...state,
  });
  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    ref.current.width = "10%";
    qrCode.width = "10px";
    setState({ width: "100%", height: "100%" });
    if (size == "a4") {
      setState1({ width: "100px", height: "100px", objectFit: "contain" });
      // setState({ width: "8.4", objectFit: "contain" });
    } else if (size == "a5") {
    }
  }, [size]);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
    // qrCode?._canvas?.width = "16.66667%";
  }, [url]);
  const onUrlChange = event => {
    event.preventDefault();
    // setUrl(event.target.value);
  };
  useEffect(() => {}, [url]);

  const onExtensionChange = event => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt,
    });
  };
  //  &.bottom-left {
  //     left: 25px !important;
  //     bottom: 25px;
  //   }

  //   &.bottom-right {
  //     right: 25px;
  //     bottom: 25px;
  //   }

  //   &.top-right {
  //     top: 25px;
  //     right: 25px;
  //   }

  //   &.top-left {
  //     top: 25px;
  //     left: 25px;
  //   }
  const renderSize = () => {
    switch (pos) {
      case "bottom-left":
        return { bottom: "25px", left: "25px" };
        break;
      case "bottom-right":
        return { bottom: "25px", right: "25px" };
      case "top-right":
        return { top: "25px", right: "25px" };
      case "top-left":
        return { top: "25px", left: "25px" };
      default:
        break;
    }
    return {};
  };
  return (
    <>
      <div className="qr-code-wrapper">
        <div
          className="kir-to-cartino"
          style={{
            position: "absolute",
            ...renderSize(),
            ...state1,
            borderRadius: "5px",
          }}
          ref={ref}
        />
        {hidden ? <></> : <> </>}
      </div>
    </>
  );
}

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20,
  },
};
