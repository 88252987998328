import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  cutText,
  renderStatus as renderStatusOrder,
  ToDateJalali,
  ToTimeJalali,
} from "../../Utils/functions";
import QRCode from "../qrcode";
import DefaultCard from "./Components/DefaultCard";
import DownloadQrCode from "./downLoadQrCode";
import { useOrderDetails, usePremium } from "./Hooks";
import PostalCode from "./postalCode";
import { FaSpinner } from "react-icons/fa";
import Region from "../Form/Components/Region";
import Select from "../Form/Components/Select";

const OrderDetails = ({ option }) => {
  const { data, loading, id, ready, loadingBtn, setData, submitNote, setNote, note, dataAddress, submitNewAddress, newAddress, loadingNewAddressBtn,
    submitAddress, loadingAddressBtn, setNewAddress, addressModal, setAddressModal,
    loadingNoteBtn } =
    useOrderDetails(option);
  const { loadingBtnPremium, toggle } = usePremium({ data, setData });
  const renderStatus = data => {
    switch (data?.status) {
      case "success":
        return {
          component: () => {
            return (
              <div className="badge rounded-pill badge-soft-success fs--2">
                تکمیل شد
                <span
                  className="fas fa-check ms-1"
                  data-fa-transform="shrink-2"
                />
              </div>
            );
          },
          status: true,
        };
      case "waittopey":
        return {
          component: () => {
            return (
              <div className="badge rounded-pill badge-soft-warning text-dark fs--5">
                <span className="badge badge rounded-pill d-block text-danger ">
                  منتظر پرداخت
                  <svg
                    className="svg-inline--fa fa-redo fa-w-16 ms-1"
                    data-fa-transform="shrink-2"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="redo"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg
                    style={{ transformOrigin: "0.5em 0.5em" }}
                  >
                    <g transform="translate(256 256)">
                      <g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)">
                        <path
                          fill="currentColor"
                          d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
                          transform="translate(-256 -256)"
                        />
                      </g>
                    </g>
                  </svg>
                  {/* <span class="ms-1 fas fa-redo" data-fa-transform="shrink-2"></span> Font Awesome fontawesome.com */}
                </span>
              </div>
            );
          },
          status: false,
        };
      default:
        return {
          component: () => {
            return (
              <div className="badge rounded-pill badge-soft-danger text-dark fs--5">
                <span className="badge badge rounded-pill d-block text-danger ">
                  {renderStatusOrder(data.status)["label"]}
                  <svg
                    className="svg-inline--fa fa-ban fa-w-16 ms-1"
                    data-fa-transform="shrink-2"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="ban"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg
                    style={{ transformOrigin: "0.5em 0.5em" }}
                  >
                    <g transform="translate(256 256)">
                      <g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)">
                        <path
                          fill="currentColor"
                          d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
                          transform="translate(-256 -256)"
                        />
                      </g>
                    </g>
                  </svg>
                  {/* <span class="ms-1 fas fa-redo" data-fa-transform="shrink-2"></span> Font Awesome fontawesome.com */}
                </span>
              </div>
            );
          },
          status: true,
        };
        break;
    }
  };

  const printKiri = () => {
    window.open(`https://api.thecartino.ir/api/print?id=${id}`, "_blank");
  };
  const render = () => {
    if (data.details) {
      switch (data.product_id) {
        case 1:
        case "1":

          if (data.details.back_image_url && data.details.front_image_url) {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={data.details.back_image_url}
                />
                <img
                  src={data.details.front_image_url}
                  style={{ width: "100%" }}
                />
              </div>
            );
          } else {
            return (
              <>
                <DefaultCard data={data} />
              </>
            );
          }
          break;
        case 3:
        case "3":
          if (data?.details?.design_id) {
            return (
              <>
                <div className="row mb-1">
                  <div className="col">
                    <h5>طرح قاب مورد نظر</h5>
                    <p>
                      طرف پیش فرض میباشد با شماره ی {data?.details?.design_id}
                    </p>
                    <p>{`رنگ قاب : ${data?.details?.color}`}</p>
                    <p>{`سایز : ${data?.details?.size}`}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div style={{}} className="kit-to-ali fawfkamfkamwkfkawfak">
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={`https://api.thecartino.ir/frame/${data?.details?.design_id}.jpg`}
                      />
                      {data?.details?.qr_code == "true" ? (
                        <>
                          <QRCode
                            size={data?.details?.size}
                            url={`https://thecartino.ir/f/${data?.rel?.key}`}
                            pos={data?.details?.qr_location}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="mt-2">
                      <button className="btn btn-danger" onClick={printKiri}>
                        چاپ طرح
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        printAli();
                      }}
                    >
                      چاپ طرح
                    </button> */}
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div className="row mb-1">
                  <div className="col">
                    <h5>طرح قاب مورد نظر</h5>
                    <p>{`رنگ قاب : ${data?.details?.color}`}</p>
                    <p>{`سایز : ${data?.details?.size}`}</p>
                    {data?.details?.qr_code == "true" && (
                      <p>نیاز به qrcode دارد</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div style={{}}>
                      <img
                        style={{ width: "100%" }}
                        src={data?.details?.image_url}
                      />
                      {/* {data?.details?.qr_code == "true" ? (
                        <>
                          <QRCode
                            size={data?.details?.size}
                            url={`https://thecartino.ir/f/${data?.rel?.key}`}
                          />
                        </>
                      ) : (
                        <></>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12">
                    {data?.details?.qr_code == "true" ? (
                      <>
                        {/* <QRCode
                            size={data?.details?.size}
                            url={`https://thecartino.ir/f/${data?.rel?.key}`}
                            pos={data?.details?.qr_location}
                          /> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mt-2">
                    <button className="btn btn-danger" onClick={printKiri}>
                      چاپ طرح
                    </button>
                  </div>
                </div>
              </>
            );
          }
          break;
        case 4:
        case "4":

          if (data?.details?.design_id) {
            return (
              <>
                <div className="row mb-1">
                  <div className="col">
                    <p>
                      طرف پیش فرض میباشد با شماره ی {data?.details?.design_id}
                    </p>
                    <p>{`رنگ و جنس عابر بانک: ${data?.details?.color}`}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div style={{}} className="kit-to-ali fawfkamfkamwkfkawfak">
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={data?.details?.design_photo_url}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div className="row mb-1">
                  <div className="col">
                    <p>{` رنگ و جنس عابر بانک : ${data?.details?.color}`}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div style={{}}>
                      <img
                        style={{ width: "100%" }}
                        src={data?.details?.front_image_url}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }
          break;
        case 5:
        case "5":

          if (data.details.back_image_url && data.details.front_image_url) {
            return (
              <>
                <div className="row mb-1">
                  <div className="col">
                    <p>{` رنگ و جنس عابر بانک : ${data?.details?.color}`}</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <span>ٰروی کارت : </span>
                  <img
                    src={data.details.front_image_url}
                    style={{ width: "100%" }}
                  />
                  <span>پشت کارت : </span>
                  <img
                    style={{ width: "100%" }}
                    src={data.details.back_image_url}
                  />
                </div>
              </>
            );
          } else {
            return (
              <>
              </>
            );
          }
          break;
        default:
          break;
      }
    } else {
      return <></>;
    }
  };
  if (loading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
  }
  const renderUrl = () => {
    switch (data.product_id) {
      case 1:
      case "1":
        return `https://thecartino.ir/c/${data?.rel?.key}`;
        break;
      case 3:
      case "1":
        return `https://thecartino.ir/f/${data?.rel?.key}`;
      default:
        break;
    }
  };
  return (
    <>
      <div className="content">
        <div className="card mb-3">
          <div
            className="bg-holder d-none d-lg-block bg-card"
            style={{
              backgroundImage:
                "url(../../../assets/img/icons/spot-illustrations/corner-4.png)",
              opacity: "0.7",
            }}
          />
          {/*/.bg-holder*/}
          <div className="card-body position-relative">
            <h5>جزئیات سفارش: #{id}</h5>
            <p className="fs--1">{ToTimeJalali(data?.order?.created_at)}</p>
            <div>
              <strong className="me-2">وضعیت پرداخت:</strong>
              {renderStatus(data).component()}
            </div>

            <div className="mt-2">
              {data.product_id != 2 && data.status == "success" && data.rel && (
                <>
                  <button
                    className="btn btn-danger"
                    onClick={() => ready(data.id)}
                  >
                    {loadingBtn ? (
                      <>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "تبدیل وضعیت سفارش به در حال ارسال"
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-8  mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">مشخصات کاربر</h5>

                <h6 className="mb-2">
                  نام و نام خانوادگی :{" "}
                  {`${data?.client?.first_name && data?.client?.last_name
                    ? `${data?.client?.first_name} ${data?.client?.last_name}`
                    : `ثبت نشده`
                    }`}
                </h6>
                <h6 className="mb-2">
                  ایمیل : {data?.client?.email || "ثبت نشده است"}
                </h6>
                <p className="mb-0 fs--1">
                  <a href={`tel:+${data?.client?.mobile}`}>
                    شماره تلفن :{data?.client?.mobile}
                  </a>
                </p>
                {data?.receiver && (
                  <>
                    <p>{`آدرس : ${data.receiver?.province?.title} ${data.receiver?.city?.title} ${data.receiver?.full_address}`}</p>
                  </>
                )}
                <p>{`کد رهگیری پست : ${data.post_tracking_code || "ثبت نشده"
                  }`}</p>
              </div>
              {renderStatus(data)["status"] && (
                <div className="col-md-4 ">
                  <h5 className="mb-3 fs-0">روش پرداخت</h5>
                  <span className="badge badge-soft-success">آنلاین</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <div className="table-responsive fs--1">
              <table className="table table-striped border-bottom">
                <thead className="bg-200 text-900">
                  <tr>
                    <th className="border-0">محصولات</th>
                    <th className="border-0 text-center">تعداد</th>
                    <th className="border-0 text-end">نرخ</th>
                    <th className="border-0 text-end">میزان</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-200">
                    <td className="align-middle">
                      <h6 className="mb-0 text-nowrap">{data?.product?.title}</h6>
                      <p className="mb-0">
                        {cutText(data?.product?.description, 60)}
                      </p>
                    </td>
                    <td className="align-middle text-center">1</td>
                    <td className="align-middle text-end">
                      {data?.product?.price} تومان
                    </td>
                    <td className="align-middle text-end">
                      {data?.order?.growth_price} تومان
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row g-0 justify-content-end">
              <div className="col-auto">
                <table className="table table-sm table-borderless fs--1 text-end">
                  <tbody>
                    <tr>
                      <th className="text-900">قیمت:</th>
                      <td className="fw-semi-bold">{data?.growth_price} تومان</td>
                    </tr>
                    <tr>
                      <th className="text-900">تخفیف:</th>
                      <td className="fw-semi-bold">{data?.discount} تومان</td>
                    </tr>
                    {data?.off_code && (
                      <>
                        <tr>
                          <th className="text-900">کد تخفیف:</th>
                          <td className="fw-semi-bold">{data?.off_code}</td>
                        </tr>
                      </>
                    )}
                    <tr className="border-top">
                      <th className="text-900">جمع:</th>
                      <td className="fw-semi-bold">{data?.total_price} تومان</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {data?.receiver ?
          (
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <PostalCode
                      receiverName={data?.receiver?.full_name}
                      receiverAddress={`${data?.receiver?.province?.title} ${data?.receiver?.city?.title} ${data?.receiver?.full_address}`}
                      receiverCode={data?.receiver?.postal_code}
                      receiverPhone={data?.receiver?.mobile}
                      orderDate={ToDateJalali(data?.created_at)}
                      printDate={ToDateJalali()}
                      receiptNumber={data.id}
                      post_tracking_code={data.post_tracking_code}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5>ثبت آدرس روی سفارش</h5>
                    <p>لیست آدرس های کاربر</p>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <div>
                      <button className="btn btn-danger" onClick={() => { setAddressModal(true) }}>
                        افزودن سریع آدرس
                      </button>
                    </div>
                  </div>

                </div>

                <div className="table-responsive fs--1 mt-3">
                  <table className="table table-striped border-bottom">
                    <thead className="bg-200 text-900">
                      <tr>
                        <th className="border-0">#</th>
                        <th className="border-0 text-center">استان</th>
                        <th className="border-0 text-center">شهرستان</th>
                        <th className="border-0 text-center">کد پستی</th>
                        <th className="border-0 text-center">آدرس</th>
                        <th className="border-0 text-center" style={{ width: "1%" }}></th>

                      </tr>
                    </thead>
                    <tbody>
                      {!dataAddress.length ? <tr><td colSpan={'100%'} className="align-middle text-center">بدون آدرس</td></tr> : (
                        dataAddress.map((e, i) => {
                          return (
                            <tr className="border-200" key={i}>
                              <td className="align-middle">
                                {i + 1}
                              </td>
                              <td className="align-middle text-center">{
                                e.province?.title
                              }</td>
                              <td className="align-middle text-center">
                                {
                                  e.city?.title
                                }
                              </td>
                              <td className="align-middle text-center">
                                {e.postal_code}
                              </td>
                              <td className="align-middle text-center">
                                {e.full_address}
                              </td>
                              <td className="align-middle text-center">
                                <button className="btn btn-danger" onClick={() => { submitAddress(e.id) }}>
                                  {
                                    loadingAddressBtn ? <FaSpinner /> : 'انتخاب'
                                  }
                                </button>
                              </td>
                            </tr>
                          )

                        })
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        <div className="card mb-3">
          <div className="card-body">
            <h5>یادداشت روی سفارش</h5>
            <div className="mt-2">
              <textarea className="w-100" cols="30" rows="10" defaultValue={data.note} onChange={(e) => setNote(e.target.value)} />
            </div>
            <div className="mt-3 d-flex justify-content-end">
              <button className="btn btn-danger" onClick={submitNote}>
                {
                  loadingNoteBtn ? <FaSpinner /> : 'ذخیره'
                }
              </button>
            </div>
          </div>
        </div>
        {data.product_id == 1 && (
          <div className="card mb-3">
            <div className="card-body">
              <div className="d-flex">
                <h5 className="mb-3">زبان فعلی محصول: </h5>
                <h5 className="mb-3">
                  {data?.rel?.lang == "fa" ? " فارسی " : " انگلیسی "}{" "}
                </h5>
              </div>

              <button onClick={toggle} className="btn btn-primary">
                {loadingBtnPremium ? <>loading ...</> : "تغیر زبان"}
              </button>
            </div>
          </div>
        )}
        <div className="card mt-3">
          <div className="card-body">
            {data.complete == 1 && (
              <div className="d-flex text-center">
                <h5 className="mb-3">تاریخ ثبت طرح :</h5>
                <h5 className="mb-3">{ToDateJalali(data?.complete_date)}</h5>
              </div>
            )}
            <div className="row">{render()}</div>
          </div>
        </div>
        {
          data.product_id != 4 && data.product_id != 5 ? (
            <div className="card mt-3">
              <div className="card-body">
                <h5>qrcode && link</h5>
                <p className="fs--1">{ToTimeJalali(data?.order?.created_at)}</p>
                {data?.rel?.key && (
                  <>
                    {" "}
                    <p>
                      <a href={renderUrl()}>لینک محصول : {renderUrl()}</a>
                      <button
                        className="btn btn-danger "
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                          navigator.clipboard.writeText(renderUrl());
                          toast.success("با موفقیت کپی شد");
                        }}
                      >
                        کپی
                      </button>
                    </p>
                    <div className="my-4">
                      <DownloadQrCode url={renderUrl()} />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <></>
          )
        }
      </div>



      {
        data.address_id == null && (
          <div
            className={`modal fade ${addressModal ? "show" : "d-none"}  `}
            id="address-modal"
            tabIndex={-1}
            aria-modal="true"
            role="dialog"
            style={{
              backgroundColor: "rgba(0,0,0,0.6)",
              display: data.status ? "block" : "none",
            }}>
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              style={{ maxWidth: 500 }}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button
                    className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal"
                    aria-label="نزدیک"
                    onClick={() => setAddressModal(false)}
                  />
                </div>
                <div className="modal-body p-0">
                  <div className="form p-3">
                    <div className="mt-4">
                      <Region set={(key, value) => { setNewAddress({ ...newAddress, [key]: value }) }} outSide={true} />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="full_address">ادرس کامل</label>
                      <input
                        id='full_address'
                        onChange={({ target: { value } }) => {
                          setNewAddress({ ...newAddress, full_address: value });
                        }}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="postal_code">کد پستی</label>
                      <input
                        id='postal_code'
                        onChange={({ target: { value } }) => {
                          setNewAddress({ ...newAddress, postal_code: value });
                        }}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="type">تحویل گیرنده</label>
                      <select
                        onChange={({ target: { value } }) => {
                          setNewAddress({ ...newAddress, type: value });
                        }}
                        className="form-control"
                        id="type"
                      >
                        <option disabled className="d-none" selected>انتخاب کنید</option>
                        <option value={'self'}>خود کاربر </option>
                        <option value={'other'}>شخص دیگر</option>
                      </select>
                    </div>
                    {
                      newAddress.type == 'other' && (
                        <>
                          <div className="mt-2">
                            <label htmlFor="full_name">نام گیرنده </label>
                            <input
                              id='full_name'
                              onChange={({ target: { value } }) => {
                                setNewAddress({ ...newAddress, full_name: value });
                              }}
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="mt-2">
                            <label htmlFor="mobile"> شماره تماس گیرنده</label>
                            <input
                              id='mobile'
                              onChange={({ target: { value } }) => {
                                setNewAddress({ ...newAddress, mobile: value });
                              }}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    onClick={() => submitNewAddress()}
                  >
                    {loadingNewAddressBtn ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    ) : (
                      'ثبت'
                    )}
                  </button>
                  <button className="btn btn-danger "
                    onClick={() => setAddressModal(false)}
                  >
                    لفو
                  </button>

                </div>
              </div>
            </div>
          </div>
        )
      }



    </>

  );
};

export default OrderDetails;
