import React, { useState } from "react";
import { useMainContext } from "../../../../../Context";

export const useRegion = ({ set = () => { } }) => {
  const { post } = useMainContext();
  const [provinec, setProvinec] = useState([]);
  const [citys, setCitys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});
  const getProvince = async () => {
    setLoading(true);

    const { data } = await post("/provinces");
    if (data) {
      setProvinec(data);
    }
    setLoading(false);
  };

  const getCity = async id => {
    setLoading(true);

    set("province_id", id);
    const { data } = await post("/cities", { province_id: id });
    if (data) {
      setCitys(data);
    }
    setLoading(false);
  };

  return {
    loading,
    citys,
    provinec,
    getProvince,
    getCity,
    setForm,
    form,
  };
};
