import React, { useEffect, useState } from "react";
import { useTable } from "./Hooks";
import Pagination from "react-js-pagination";
import { useLocation } from "react-router-dom";
import ModalContent from "./Components/ModalContent";
import Filter from "./Components/Filter";

const Table = ({ options }) => {
  const { pathname } = useLocation();
  const {
    getData,
    data,
    loading,
    renderCloumn,
    renderAction,
    areUSure,
    setAreUSure,
    LoadingDelete,
    deleteRow,
    isIn,
    toggle,
    length,
    selected,
    searchParams,
    setSearchParams,
    changeObj,
    filterDelete,
    exportExcel,
    LoadingExcel,
  } = useTable({
    route: options.api.route,
    keyRoute: options.api.key ?? null,
    elements: [],
    selectAble: options.selectAble || {},
    filters: options.filters
      ? [...options.filters, { key: "page" }, { key: "limit" }]
      : [{ key: "page", key: "limit" }],
    dashboard: options.dashboard || {},
    // sortInint: options.sortInint || {},
  });
  const selectAble = options.selectAble || {};
  const filters = options.filters
    ? [...options.filters, { key: "page" }, { key: "limit" }]
    : [{ key: "page", key: "limit" }];
  useEffect(() => {
    if (!areUSure.status && !options.dashboard) {
      getData();
    }
  }, [pathname, areUSure.status, searchParams]);

  const [sort, setSort] = useState({});

  const pagenate = () => {
    return (
      <div className=" mb-1">
        <div className="row justify-content-center">
          <Pagination
            activePage={data.current_page}
            itemsCountPerPage={data.per_page}
            totalItemsCount={data.total}
            onChange={data => changeObj("page", data)}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        </div>
      </div>
    );
  };
  const renderData = () => {
    if (options?.dashboard?.data) {
      return options.dashboard.data;
    } else {
      return data.data;
    }
    return [];
  };
  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card" data-list={""}>
          {/* <button
            type="button"
            className="btn btn-secondary"
            data-toggle="tooltip"
            data-placement="top"
            title="Tooltip on top">
            Tooltip on top
          </button> */}

          <div className="card-body">
            <h4 className="card-title mb-2">{options.label}</h4>
            <div className="row  align-items-center mb-2">
              {!options.dashboard && (
                <div className="col-lg-2 col-md-4 col-6 my-3">
                  <label htmlFor="searchTable">جستجو</label>
                  <input
                    id="searchTable"
                    type="search"
                    placeholder="جستجو ..."
                    className="form-control"
                    onChange={({ target: { value } }) => {
                      changeObj("search", value);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="row mb-2">
              {filters.length > 0 && (
                <div className="col-12">
                  <Filter
                    filterDelete={filterDelete}
                    filters={filters}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    setAreUSure={setAreUSure}
                    changeObj={changeObj}
                  />
                </div>
              )}
            </div>

            {loading && !options.dashboard ? (
              <div className="d-flex  justify-content-center my-10">
                <span
                  class="spinner-border spinner-border"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            ) : (
              <>
                {data?.data?.length == 0 ? (
                  <>
                    <div className="border rounded">
                      <h5 className="text-center my-5">موردی یافت نشد</h5>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="table-responsive scrollbar">
                      <table className="table table-bordered table-striped fs--1 mb-0">
                        <thead className="bg-200 text-900">
                          <tr>
                            {/* <th>
                          {selectAble.status ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={toggleAll}
                              checked={isSelectedAll()}
                            />
                          ) : (
                            <th>#</th>
                          )}
                        </th> */}
                            <th>#</th>

                            {options.header &&
                              options.header.map(head => {
                                return (
                                  <th
                                    className="sort"
                                    onClick={() => {
                                      setSort({
                                        ...head,
                                        sort: head.sort ? "" : "",
                                      });
                                    }}
                                    data-sort="dw"
                                  >
                                    {head.label}
                                  </th>
                                );
                              })}
                            {options.actions && options.actions.length > 0 ? (
                              <th></th>
                            ) : (
                              <></>
                            )}
                          </tr>
                        </thead>
                        <tbody className="list">
                          {renderData().map((item, count) => {
                            return (
                              <tr>
                                <td>
                                  {" "}
                                  {selectAble.status ? (
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={isIn(item.id)}
                                      onClick={() => toggle(item.id)}
                                      disabled={
                                        !selectAble.statement.value?.includes(
                                          item[selectAble.statement.key]
                                        )
                                      }
                                    />
                                  ) : (
                                    <>{count + 1}</>
                                  )}
                                </td>
                                {options?.header.map(head => {
                                  return <td> {renderCloumn(item, head)}</td>;
                                })}
                                {options?.actions && (
                                  <td>
                                    {" "}
                                    {options.actions &&
                                      options.actions.map(action => {
                                        return renderAction(action, item);
                                      })}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {!options.dashboard && (
                  <>
                    <div className="mt-3">
                      <p>
                        {data.to}
                        نتیجه از
                        {data.total}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        style={{ flex: 1 }}
                        className="d-flex align-items-center "
                      >
                        <span
                          htmlFor="countresultshow"
                          style={{ fontSize: "12px", marginLeft: "5px" }}
                        >
                          تعداد نمایش داده ها:
                        </span>
                        <select
                          onChange={({ target: { value } }) => {
                            changeObj("limit", value);
                          }}
                          style={{ maxWidth: "50px" }}
                          className="form-control "
                          defaultValue={searchParams.get("limit")}
                        >
                          {Array(8)
                            .fill(0)
                            .map((_i, d) => (d + 1) * 20)
                            .map(op => (
                              <option key={op}>{op}</option>
                            ))}
                        </select>
                      </div>
                      {pagenate()}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalContent
        data={areUSure}
        setAreUSure={setAreUSure}
        LoadingDelete={LoadingDelete}
        deleteRow={deleteRow}
      />
    </>
  );
};

export default Table;
