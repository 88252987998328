import React from 'react'
import Clock from "./Components/Clock";

const SalesManager = () => {
   return (
      <>
        <div className="row mb-2">
          <div className="col-lg-3">
            <Clock size={200} />
          </div>
        </div>
      </>
    );
}

export default SalesManager