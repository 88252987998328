import React from "react";

const Select = ({ handleChange, handleValue, element, loading, data }) => {
  if (element.need && loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
  }
  const renderOptions = () => {
    if (element.need) {
      return data.map(option => {
        return <option value={option.value}>{option.label}</option>;
      });
    } else {
      return element.options.map(option => {
        return <option value={option.key}>{option.name}</option>;
      });
    }
  };
  return (
    <div className="form-group">
      <div className="form-group">
        <label htmlFor="exampleSelectGender">{element.label}</label>
        <select
          onChange={({ target: { value } }) => {
            handleChange(element.key, value, element.nested);
          }}
          value={handleValue(element.key, element.nested)}
          className="form-control"
          id="exampleSelectGender"
        >
          <option>انتخاب کنید</option>
          {renderOptions()}
        </select>
      </div>
    </div>
  );
};

export default Select;
