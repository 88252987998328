import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../Context";

const Navbar = () => {
  const { state } = useMainContext();
  return (
    <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
      <button
        className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVerticalCollapse"
        aria-controls="navbarVerticalCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </button>
      <Link
        to="/dashboard"
        className="navbar-brand me-1 me-sm-3"
        href="index.html"
      >
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={window.origin + "/assets/img/logo-light.svg"}
            alt
            width={40}
          />
          <span className="font-sans-serif">کارتینو</span>
        </div>
      </Link>
      <ul className="navbar-nav align-items-center d-none d-lg-block">
        <li className="nav-item">
          <div className="mt-3">
            <p style={{ fontSize: "15px" }}>
              سلام{" "}
              {state?.user?.user?.first_name +
                " " +
                state?.user?.user?.last_name}{" "}
              عزیز !
            </p>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
