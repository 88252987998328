import React from "react";
import { useAuth } from "./Hooks";

const Login = () => {
  const { check, handler, loading, form } = useAuth();
  return (
    <div className="container-fluid">
      <div className="row min-vh-100 flex-center g-0">
        <div className="col-lg-8 col-xxl-5 py-3 position-relative">
          <img
            className="bg-auth-circle-shape"
            src="../../../assets/img/icons/spot-illustrations/bg-shape.png"
            alt
            width={250}
          />
          <img
            className="bg-auth-circle-shape-2"
            src="../../../assets/img/icons/spot-illustrations/shape-1.png"
            alt
            width={150}
          />
          <div className="card overflow-hidden z-index-1">
            <div className="card-body p-0">
              <div className="row g-0 h-100">
                <div className="col-md-5 text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                    <div
                      className="bg-holder bg-auth-card-shape"
                      style={{
                        backgroundImage:
                          "url(../../../assets/img/icons/spot-illustrations/half-circle.png)",
                      }}
                    />
                    {/*/.bg-holder*/}
                    <div className="z-index-1 position-relative">
                      <img
                        className="me-2 header-logo"
                        src={window.origin + "/assets/img/logo-light.svg"}
                        alt
                        width={120}
                        height={120}
                      />
                      <p>سامانه جامع آنلاین</p>
                      <a className="link-light mb-4 font-sans-serif fs-2 d-inline-block fw-bolder">
                        کارتینو
                      </a>
                      <p className="opacity-75 text-white">خوش آمدید!</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 d-flex flex-center">
                  <div className="p-4 p-md-5 flex-grow-1">
                    <div className="row flex-between-center mb-3">
                      <div className="col-auto">
                        <h3>ورود به پنل مدیریت</h3>
                      </div>
                    </div>
                    <div>
                      <div className="mb-2">
                        <label htmlFor="card-email">نام کاربری</label>
                        <input
                          className="form-control"
                          id="card-email"
                          onChange={({ target: { value } }) =>
                            handler("mobile", value)
                          }
                          onKeyDown={e => {
                            if (e.key == "Enter") {
                              check();
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="card-password">رمز عبور</label>
                        </div>
                        <input
                          className="form-control"
                          id="card-password"
                          type="password"
                          onChange={({ target: { value } }) =>
                            handler("password", value)
                          }
                          onKeyDown={e => {
                            if (e.key == "Enter") {
                              check();
                            }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <button
                          className="btn btn-primary d-block w-100 mt-3"
                          type="submit"
                          name="submit"
                          onClick={check}
                          disabled={loading.verify}
                        >
                          {loading.verify ? "loading ..." : "ورود"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer
        className="position-fixed w-100"
        style={{
          bottom: "10vh",
        }}
      >
        <p className="text-center">
          توسعه و پیاده سازی تخصصی در تیم طراحی و توسعه آکو | نسخه 1.0.0
        </p>
      </footer>
    </div>
  );
};

export default Login;
