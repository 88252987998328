import React, { useEffect } from "react";
import { useRegion } from "./Hooks";

function Region({ set = () => { }, data = {}, value, outSide = false }) {
  const { loading, citys, provinec, getProvince, getRegion, getCity } =
    useRegion({ set });
  useEffect(() => {
    getProvince();
    if (!outSide) {
      if (value("province_id") != "") getCity(value("province_id"));
    }
  }, []);
  return (
    <>
      {!outSide && value("city_id") != "" ? (
        <div className="row">
          <div className="col">
            <h6>
              مقادیر قبلی :{" "}
              {`${value("title", 'province')} - ${value("title", 'city')} `}
            </h6>
          </div>
        </div>
      ) : (<></>)}
      <div className="row">
        <div className="col-12 ">
          <label htmlFor="">استان</label>
          <select
            onChange={({ target: { value } }) => getCity(value)}
            className="form-control"
            value={!outSide ? value("province_id") : value?.province_id}>
            <option value="">انتخاب کنید</option>
            {provinec.map((element, key) => (
              <option key={key} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 ">
          <label htmlFor="">شهرستان</label>
          <select
            value={!outSide ? value("city_id") : value?.city_id}
            className="form-control"
            onChange={({ target: { value } }) => set("city_id", value)}>
            <option value="">انتخاب کنید</option>

            {citys.map((element, key) => (
              <option key={key} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default Region;
