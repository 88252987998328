import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import QRCodeStyling from "qr-code-styling";
import "../index.css";
import * as htmlToImage from "html-to-image";
import { saveAs } from "../../../Utils/functions";
import qr_code from "../options.json";
import { useMainContext } from "../../../Context";
import axios from "axios";
const server = axios.create({});
const DefaultCard = ({ data }) => {
  const [image, setImage] = useState(null);
  const qrCode = new QRCodeStyling({
    ...qr_code,
    width: 1000,
    height: 1000,
  });
  const ref = useRef(null);
  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  const renderUrl = () => {
    switch (data.product_id) {
      case 1:
      case "1":
        return `https://thecartino.ir/c/${data?.rel?.key}`;
        break;
      case 3:
      case "1":
        return `https://thecartino.ir/f/${data?.rel?.key}`;
      default:
        break;
    }
  };
  useEffect(() => {
    qrCode.update({
      data: `${renderUrl()}`,
    });
    // qrCode?._canvas?.width = "16.66667%";
  }, [data]);

  const { post, postFile } = useMainContext();
  const getImage = async url => {
    const data = await postFile("/admin/return/image", { url });
    setImage(data || {});
    console.log("da", data);
  };
  useEffect(() => {
    getImage(data.details.picture);
  }, []);

  return (
    <>
      <div className="m-5">
        <div className="result-preview-cartino">
          <div className=" htmlToImageVis front-p ">
            <div className="style-design-card-id">
              {<img src={image && URL.createObjectURL(image)} />}
            </div>
            <p
              style={{
                fontWeight: 800,
                color: "#404061",
                marginBottom: 0,
                fontSize: "10px",
                fontFamily: "body-font",
                marginTop: "15px",
              }}
            >
              {data.details.persian_text}
            </p>
            <p
              style={{
                fontWeight: 800,

                color: "#404061",
                marginBottom: 0,
                fontSize: "10px",
              }}
            >
              {data.details.english_text}
            </p>
          </div>
          <div className="back-p htmlToImageVis">
            <div
              className="qr-code-default-card"
              style={{
                width: "237px",
                height: "237px",
                objectFit: "contain",
                borderRadius: "5px",
                marginTop: "75px",
              }}
              ref={ref}
            />
            <div className="fw-600 back-p-footer">
              <p
                style={{
                  paddingBottom: "15px",
                }}
              >
                TheCartino.ir
              </p>
            </div>
          </div>
        </div>
        <DownLoad />
      </div>
      <div className="d-flex gap-2 ">
        <div className="form-group my-2 flex-1">
          <label htmlFor="exampleInputName1">متن فارسی</label>
          <input
            value={data.details.persian_text || "ثبت نشده"}
            type="text"
            className="form-control"
            id="exampleInputName1"
            readOnly
          />
        </div>
        <div className="form-group my-2 flex-1">
          <label htmlFor="exampleInputName1">متن انگلیسی</label>
          <input
            value={data.details.english_text || "ثبت نشده"}
            type="text"
            className="form-control"
            id="exampleInputName1"
            readOnly
          />
        </div>
        <div className="flex-1">
          <label htmlFor="exampleInputName1">تصویر وسط</label>
          <div>
            <img
              style={{ width: "71px", height: "71px", borderRadius: "50%" }}
              src={data.details.picture}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultCard;
const DownLoad = () => {
  const back = () => {
    var data = document.getElementsByClassName("htmlToImageVis");

    for (var i = 0; i < data.length; ++i) {
      htmlToImage.toPng(data[i]).then(dataUrl => {
        saveAs(dataUrl, "my-node.png");
      });
    }
  };
  return (
    <div className="mt-5 d-flex justify-content-center mt-9">
      <button className="btn btn-dark" onClick={back}>
        دانلود
      </button>
    </div>
  );
};
