import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { convertKeys, ToDateDayMonthJalali } from "../../../../Utils/functions";

const Plot = ({ label, data }) => {
  const demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";

  return (
    <div className="col-12 card">
      <div className="card-body">
        <h5>{label}</h5>

        <ResponsiveContainer width="100%" aspect={3}>
          <LineChart
            width={500}
            height={300}
            data={convertKeys(data, { label: "name", value: "uv" })
              .reverse()
              .map(item => {
                return {
                  name: ToDateDayMonthJalali(item.name),
                  فروش: +item.uv,
                };
              })}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Line
              type="monotone"
              dataKey="فروش"
              strokeWidth="3"
              stroke="#4C72AC"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default Plot;
