import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { useMainContext } from "../../Context";
import Layout from "../../Layouts";
import { isJson, sortSettings, timeSince } from "../../Utils/functions";
import Dashboard from "../Dashboard";
import Form from "../Form";
import FormV1 from "../Form-V1";
import NotFound from "../NotFound";
import Support from "../Support";
import Surfaces from "../Surfaces";
import Table from "../Table";
import { object } from "prop-types";
const dashboard = {
  label: "پیشخوان",
  icon: "fab fa-trello",
  route: "/",
  nested: false,
  access: ["admin", "sales_manager", "seller", "editor"],
  options: [
    {
      route: "/",
      type: "dashboard",
      elements: [],
    },
  ],
};
const categories = {
  label: "دسته بندی",
  icon: "",
  nested: true,
  access: ["admin", "editor"],
  icon: "fas fa-plus-square",
  options: [
    {
      route: "/category/create",
      type: "form",
      show: true,
      label: "ایجاد دسته بندی",
      api: {
        route: "/category",
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          label: "توضیحات",
          type: "textarea",
          key: "description",
          noNeed: true,
        },
        {
          key: "type",
          label: "نوع",
          type: "select",
          options: [
            {
              name: "مقاله",
              key: "blog",
            },
            {
              name: "محصول",
              key: "product",
            },
          ],
        },
        {
          key: "keywords",
          type: "keywords",
          label: "کلمات کلیدی سئو",
          nested: "seo",
          noNeed: true,
        },
        {
          label: "توضیحات سئو",
          type: "textarea",
          nested: "seo",
          key: "seoDescription",
          noNeed: true,
        },

        {
          key: "photo",
          label: "تصویر دسته بندی",
          type: "images",
        },
      ],
    },
    {
      route: "/category/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش دسته بندی",
      api: {
        route: "/category",
        show: {
          route: "/category",
          key: "category_id",
        },
      },
      sortInint: (state) => {
        return {
          ...state,
          photo: state?.phtot?.url || false,
          category_id: state.id,
        };
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          label: "توضیحات",
          type: "textarea",
          key: "description",
          noNeed: true,
        },
        {
          key: "type",
          label: "نوع",
          type: "select",
          options: [
            {
              name: "مقاله",
              key: "blog",
            },
            {
              name: "محصول",
              key: "product",
            },
          ],
        },
        {
          key: "keywords",
          type: "keywords",
          label: "کلمات کلیدی سئو",
          nested: "seo",
          noNeed: true,
        },
        {
          label: "توضیحات سئو",
          type: "textarea",
          nested: "seo",
          key: "seoDescription",
          noNeed: true,
        },

        {
          key: "photo",
          label: "تصویر دسته بندی",
          type: "images",
        },
      ],
    },
    {
      route: "/category/list",
      type: "table",
      show: true,
      label: "لیست دسته بندی",
      api: {
        route: "/category",
      },
      actions: [
        {
          type: "delete",
          label: "delete category",
          route: "/category",
        },
        {
          type: "show",
          label: "edit category",
          link: "/category/update",
        },
      ],
      header: [
        {
          key: "title",
          label: "عنوان",
        },

        {
          key: "type",
          label: "نوع",
          type: "enum",
          enum: { blog: "مقاله", product: "محصول" },
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const blogs = {
  label: "مقالات",
  access: ["admin", "editor"],
  nested: true,
  icon: "far fa-clone",
  options: [
    {
      route: "/blog/create",
      type: "form",
      show: true,
      label: "ایجاد مقاله",
      api: {
        route: "/blog",
        needs: ["blog_categories"],
      },
      sort: (state) => {
        return {
          ...state,
          categories_id: state?.categories_id?.map((cat) => cat.value),
        };
      },
      form: [
        {
          key: "title",
          label: "عنوان",
          col: "col-md-6",
        },
        {
          key: "slug",
          label: "اسلاگ",
          col: "col-md-6",
        },
        {
          key: "categories_id",
          label: "دسته بندی مقاله",
          type: "multiselect",
          need: "blog_categories",
          noNeed: true,
          col: "col-md-6",
        },

        {
          key: "content",
          label: "متن مقاله",
          type: "editor",
        },
        {
          key: "photo",
          label: "تصویر مقاله",
          type: "images",
          col: "col-md-6",
          noNeed: true,
        },
        {
          key: "alt",
          label: "متن جایگزین تصویر",
          col: "col-md-6",
        },
        {
          label: "توضیحات سيو",
          type: "textarea",
          nested: "seo",
          key: "seoDescription",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "description",
          label: "توضیحات",
          type: "textarea",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "tags",
          type: "keywords",
          label: "کلمات کلیدی",
          noNeed: true,
        },
        {
          key: "jsonLd",
          label: "json",
          type: "json",
        },
      ],
    },
    {
      route: "/blog/list",
      type: "table",
      show: true,
      label: "لیست مقالات",
      api: {
        route: "/blog",
      },
      actions: [
        {
          type: "delete",
          label: "حذف مقاله",
          route: "/blog",
        },
        {
          type: "show",
          link: "/blog/update",
          label: "ویرایش مقاله",
        },
      ],
      header: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "slug",
          label: "اسلاگ",
        },
        {
          key: "fullname",
          label: "نویسنده",
          type: "fullnameNested",
          objectKey: "auther",
        },
        {
          key: "created_at",
          label: "created_at",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "last upadte",
        },
      ],
    },
    {
      route: "/blog/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش مقاله",
      api: {
        route: "/blog",
        show: {
          route: "/blog",
          key: "blog_id",
        },
        needs: ["blog_categories", "admins"],
      },
      sortInint: (state) => {
        console.log("s", state);
        return {
          ...state,
          categories_id: state.categories,
        };
      },
      sort: (state) => {
        if (state.categories_id) {
          return {
            ...state,
            categories_id: state.categories_id.map((ele) => ele.value),
          };
        } else {
          return {
            ...state,
          };
        }
      },
      form: [
        {
          key: "title",
          label: "عنوان",
          col: "col-md-6",
        },
        {
          key: "slug",
          label: "اسلاگ",
          col: "col-md-6",
        },
        {
          key: "author_id",
          label: "نویسنده",
          need: "admins",
          type: "select",
          col: "col-md-6",
        },
        {
          key: "categories_id",
          label: "دسته بندی مقاله",
          type: "multiselect",
          need: "blog_categories",
          noNeed: true,
          col: "col-md-6",
        },

        {
          key: "content",
          label: "متن مقاله",
          type: "editor",
        },
        {
          key: "photo",
          label: "تصویر مقاله",
          type: "images",
          noNeed: true,
          col: "col-12",
        },

        {
          label: "توضیحات سيو",
          type: "textarea",
          nested: "seo",
          key: "seoDescription",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "description",
          label: "توضیحات",
          type: "textarea",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "tags",
          type: "keywords",
          label: "کلمات کلیدی",
          noNeed: true,
        },
        {
          key: "jsonLd",
          label: "json",
          type: "json",
        },
      ],
    },
  ],
};
const settings = {
  label: "تنظیمات",
  icon: "fas fa-cogs",
  access: ["admin"],
  nested: true,
  options: [
    {
      route: "/settings/social_media",
      type: "edit",
      show: true,
      label: "تنظیمات شبکه اجتماعی",
      api: {
        route: "/setting/update",
        show: {
          route: "/setting/input_data",
        },
        initial: { key: "social_media" },
      },
      sortInint: (state) => {
        return {
          ...sortSettings(state),
        };
      },
      sort: (state) => {
        return { ...state, id: "social_media", key: "social_media" };
      },
      form: [
        {
          key: "twitter",
          label: "آدرس توییتر",
        },
        {
          key: "instagram",
          label: "آدرس اینستاگرام",
        },
        {
          key: "whats_app",
          label: "آدرس واتساب",
        },
        {
          key: "telegram",
          label: "تلگرام",
        },
      ],
    },
    {
      route: "/settings/contact-us",
      type: "edit",
      show: true,
      label: "ارتباط با ما",
      api: {
        route: "/setting/update",
        show: {
          route: "/setting/input_data",
        },
        initial: { key: "contact_us", id: "contact_us" },
      },
      sortInint: (state) => {
        return {
          ...sortSettings(state),
        };
      },
      sort: (state) => {
        return {
          ...state,
          id: "contact_us",
          key: "contact_us",
          _method: "PUT",
        };
      },
      form: [
        {
          key: "tell",
          label: "تلفن پشتیبانی",
        },
        {
          key: "email",
          label: "ایمیل",
        },
        {
          key: "location",
          label: "لوکیشن",
          help: "لوکیشن را به شکل '32.2148754,58.1735546' وارد کنید",
        },

        {
          key: "province",
          label: "نام استان",
        },
        {
          key: "city",
          label: "نام شهرستان",
        },
        {
          key: "address",
          label: "آدرس",
          type: "textarea",
        },
      ],
    },
  ],
};
const users = {
  label: "کاربران",
  nested: true,
  icon: "far fa-user",
  access: ["admin", "sales_manager", "operator"],
  options: [
    {
      route: "/user/create",
      type: "form",
      show: true,

      label: "ایجاد کاربر",
      api: {
        route: "/user",
      },
      box: {
        route: "/user/list",
        title: "لیست کاربران",
      },
      form: [
        {
          key: "first_name",
          label: "نام",
          noNeed: true,
        },
        {
          key: "last_name",
          label: "نام خانوادگی",
          noNeed: true,
        },

        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "email",
          noNeed: true,
          label: "ایمیل",
        },
        {
          key: "national_id",
          noNeed: true,
          label: "کد ملی",
        },
        {
          key: "password",
          label: "رمز عبور",
        },
        {
          key: "role",
          noNeed: true,
          label: "نوع کاربر",
          access: ["admin"],
          type: "select",
          options: [
            {
              name: "ادمین",
              key: "admin",
            },
            {
              name: "کاربر",
              key: "client",
            },
            {
              name: "مدیر محتوا",
              key: "editor",
            },
            {
              name: "اپراتور",
              key: "operator",
            },
            {
              name: "مدیر سئو",
              key: "seo_manager",
            },
            {
              name: "مدیر فروش",
              key: "sales_manager",
            },
            {
              name: "کارشناس فروش",
              key: "seller",
            },
          ],
        },
        {
          noNeed: true,
          key: "photo",
          label: "عکس پروفایل",
          type: "images",
        },
      ],
    },
    {
      route: "/user/list",
      type: "table",
      show: true,
      label: "لیست کاربران",
      api: {
        route: "/user",
      },
      filters: [
        {
          key: "role",
          type: "select",

          options: [
            {
              label: "ادمین",
              key: "admin",
            },
            {
              label: "کاربر",
              key: "client",
            },
            {
              label: "اپراتور",
              key: "operator",
            },
            {
              label: "مدیر محتوا",
              key: "editor",
            },
            {
              label: "مدیر سئو",
              key: "seo_manager",
            },

            {
              label: "مدیر فروش",
              key: "sales_manager",
            },
            {
              label: "کارشناس فروش",
              key: "seller",
            },
          ],
        },
      ],
      actions: [
        {
          type: "delete",
          label: "حذف کاربر",
          route: "/user",
        },
        {
          type: "toggle",
          label: "تغییر وضعیت کاربر",
          key: "user_id",
          route: "/client/toggle",
        },
        {
          type: "access",
          label: "generate password",
        },
        {
          type: "show",
          link: "/user/update",
          label: "show profile",
        },
        {
          type: "address",
          link: "/user/address/list",
          label: "مشاهده آدرس ها",
        },
      ],
      header: [
        {
          key: "fullname",
          label: "نام",
          type: "fullname",
        },

        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "status",
          type: "enum",
          label: "وضعیت",
          enum: {
            true: "فعال",
            false: "غیر فعال",
            1: "فعال",
            0: "غیر فعال",
          },
        },
        {
          key: "role",
          label: "نوع کاربر",
          noNeed: true,
          access: ["admin"],
          type: "enum",
          enum: {
            admin: "ادمین",
            client: "کاربر",
            sales_manager: "مدیر فروش",
            seller: "کارشناس فروش",
            operator: "اپراتور",
            editor: "تولید محتوا",
          },
          default: "user",
        },
        {
          key: "created_at",
          label: "تاریخ ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
    {
      route: "/user/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش کاربر",
      box: {
        title: "لیست کاربران",
        route: "/user/list",
      },
      api: {
        route: "/user",
        show: {
          route: "/user",
          key: "user_id",
        },
      },
      sortInint: (state) => {
        if (state?.photo && state?.photo[0]) {
          return {
            ...state,
            photo: state?.photo[0] || "",
            user_id: state.id,
          };
        } else {
          return {
            ...state,
            user_id: state.id,
            photo: "",
          };
        }
      },
      form: [
        {
          key: "first_name",
          label: "نام",
          noNeed: true,
        },
        {
          key: "last_name",
          label: "نام خانوادگی",
          noNeed: true,
        },

        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "email",
          label: "ایمیل",
        },

        {
          key: "role",
          label: "نوع کاربر",
          type: "select",
          access: ["admin"],
          options: [
            {
              name: "ادمین",
              key: "admin",
            },
            {
              name: "کاربر",
              key: "client",
            },
            {
              name: "مدیر فروش",
              key: "sales_manager",
            },
            {
              name: "کارشناس فروش",
              key: "seller",
            },
            {
              name: "نویسنده",
              key: "editor",
            },
            {
              name: "seo_manager",
              key: "seo_manager",
            },
            {
              name: "operator",
              key: "operator",
            },
          ],
        },

        {
          key: "photo",
          label: "عکس پروفایل",
          type: "images",
        },
      ],
    },

    {
      route: "/user/address/list/:id",
      type: "table",
      show: false,
      label: "لیست آدرس مشتریان",
      api: {
        route: "/address_able",
        key: "user_id",
      },
      filters: [
        {
          key: "role",
          type: "link",
          text: "ایجاد آدرس",
          url: "/user/address/create/",
          withId: true,
          style: { backgroundColor: "#2c7be5", borderColor: "#2c7be5" },
        },
      ],
      actions: [
        {
          type: "delete",
          label: "حذف آدرس",
          route: "/address_able",
        },
        {
          type: "show",
          link: "/user/address/update",
          label: "مشاهده جزییات",
        },
      ],
      header: [
        {
          key: "title",
          label: "استان",
          type: "nested",
          objectKey: "province",
        },
        {
          key: "title",
          label: "شهرستان",
          type: "nested",
          objectKey: "city",
        },
        {
          key: "title",
          label: "منطقه",
          type: "nested",
          objectKey: "region",
        },
        {
          key: "postal_code",
          label: "کد پستی",
        },
        {
          key: "address",
          label: "آدرس کامل",
        },
        {
          key: "postal_code",
          label: "تاریخ ایجاد",
          type: "date",
        },
      ],
    },
    {
      route: "/user/address/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش آدرس",
      box: {
        title: "لیست مشتریان",
        route: "/user/list",
      },
      api: {
        route: "/address_able",
        show: {
          route: "/address_able",
          key: "user_id",
        },
      },
      form: [
        {
          key: "city_id",
          type: "region",
          label: "شهر و استان",
          noNeed: true,
        },
        {
          key: "postal_code",
          noNeed: true,
          label: "کد پستی",
        },
        {
          key: "type",
          label: "نوع گیرنده",
          type: "select",
          options: [
            {
              name: "خود کاربر",
              key: "self",
            },
            {
              name: "شخص دیگر",
              key: "other",
            },
          ],
        },
        {
          key: "full_address",
          label: "ادرس کامل*",
        },
        {
          key: "full_name",
          label: "نام گیرنده",
          noNeed: true,
          exist: {
            key: "type",
            value: "other",
          },
        },

        {
          key: "mobile",
          label: "شماره تماس گیرنده",
          noNeed: true,
          exist: {
            key: "type",
            value: "other",
          },
        },
      ],
    },
    {
      route: "/user/address/create/:id",
      type: "form",
      show: false,
      label: "ایجاد آدرس",
      api: {
        route: "/address_able",
      },
      box: {
        route: "/user/address/list",
        title: "لیست مشتریان",
      },
      sortInint: (state, id = null) => {
        if (id != null) {
          return {
            ...state,
            user_id: id,
          };
        }
      },
      form: [
        {
          key: "city_id",
          type: "region",
          label: "شهر و استان",
          noNeed: true,
        },
        {
          key: "postal_code",
          noNeed: true,
          label: "کد پستی",
        },
        {
          key: "type",
          label: "نوع گیرنده",
          type: "select",
          options: [
            {
              name: "خود کاربر",
              key: "self",
            },
            {
              name: "شخص دیگر",
              key: "other",
            },
          ],
        },
        {
          key: "full_address",
          label: "ادرس کامل*",
        },
        {
          key: "full_name",
          label: "نام گیرنده",
          noNeed: true,
          exist: {
            key: "type",
            value: "other",
          },
        },

        {
          key: "mobile",
          label: "شماره تماس گیرنده",
          noNeed: true,
          exist: {
            key: "type",
            value: "other",
          },
        },
      ],
    },
  ],
};
const categoriesFaq = {
  label: "دسته بندی FAQ",
  icon: "",
  nested: true,
  access: ["admin", "editor"],
  icon: "fas fa-question",
  options: [
    {
      route: "/faq-category/create",
      type: "form",
      show: true,
      label: "ایجاد دسته بندی FAQ",
      api: {
        route: "/faq_category",
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          label: "توضیحات",
          type: "textarea",
          key: "description",
          noNeed: true,
        },
        {
          key: "for_page",
          label: "صفحه مورد نظر",
          type: "select",
          options: [
            {
              name: "اصلی",
              key: "home",
            },
            {
              name: "محصولات",
              key: "shop",
            },
            {
              name: "محصول",
              key: "single_product",
            },
          ],
        },
        {
          key: "photo",
          label: "تصویر دسته بندی",
          type: "images",
        },
      ],
    },
    {
      route: "/faq-category/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش دسته بندی",
      api: {
        route: "/faq_category",
        show: {
          route: "/faq_category",
          key: "category_id",
        },
      },
      sortInint: (state) => {
        if (state.photo && state.photo[0]) {
          return {
            ...state,
            photo: state.photo[0],
          };
        } else {
          return {
            ...state,
            category_id: state.id,
          };
        }
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          label: "توضیحات",
          type: "textarea",
          key: "description",
          noNeed: true,
        },
        {
          key: "for_page",
          label: "صفحه مورد نظر",
          type: "select",
          options: [
            {
              name: "اصلی",
              key: "home",
            },
            {
              name: "محصولات",
              key: "shop",
            },
            {
              name: "محصول",
              key: "single_product",
            },
          ],
        },
        {
          key: "photo",
          label: "تصویر دسته بندی",
          type: "images",
        },
      ],
    },
    {
      route: "/faq-category/list",
      type: "table",
      show: true,
      label: "لیست دسته بندی FAQ",
      api: {
        route: "/faq_category",
      },
      actions: [
        {
          type: "delete",
          label: "حذف دسته بندی faq",
          route: "/faq_category",
        },
        {
          type: "show",
          label: "edit category",
          link: "/faq-category/update",
        },
      ],
      header: [
        {
          key: "title",
          label: "عنوان",
        },

        {
          key: "for_page",
          label: "صفحه",
          type: "enum",
          enum: {
            home: "اصلی",
            shop: "محصولات",
            single_product: "محصول",
          },
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const orders = {
  label: "لیست سفارشات",
  icon: "",
  nested: true,
  access: ["admin", "sales_manager", "operator"],
  icon: "fas fa-buy",
  options: [
    {
      route: "/order/list",
      type: "table",
      show: true,
      label: "لیست سفارشات",
      api: {
        route: "/order/list",
      },
      actions: [
        {
          type: "show",
          label: "جزییات سفارش",
          link: "/order/list",
        },
        {
          type: "goNoFollow",
          label: "مشاهده پیش نمایش",
          route: "",
          key: "key",
        },
        {
          type: "addFrame",
          label: "ثبت طرح",
          link: "/order/submitFrame",
        },
        {
          type: "toggleOrder",
          label: "تغییر وضعیت سفارش",
          route: "/order/change_status",
          options: [
            {
              label: "دریافت طرح",
              key: "receiving_info",
            },
            {
              label: "بررسی طرح",
              key: "review_and_issue",
            },
            {
              label: "آماده ارسال",
              key: "ready_to_send",
            },

            {
              label: "ارسال شده",
              key: "posted",
            },
            {
              label: "بازگشت از پست",
              key: "returned",
            },
            {
              label: "فعال",
              key: "active",
            },
            {
              label: "غیر فعال",
              key: "inactive",
            },
            {
              label: "مسدود",
              key: "blocked",
            },
          ],
        },
        {
          type: "trackingPost",
          label: "ثبت کدرهگیری",
          route: "/order/posted_manual",
        },
      ],
      filters: [
        {
          key: "off_code",
          type: "input",
          label: "کد تخفیف",
          noShow: true,
        },
        {
          key: "product_id",
          type: "select",
          label: "نوع محصول",
          options: [
            {
              label: "کارت هوشمند",
              key: 1,
            },
            {
              label: "لینک هوشمند",
              key: 2,
            },
            {
              label: "قاب عکس هوشمد",
              key: 3,
            },
            {
              label: "عابر بانک",
              key: 4,
            },
            {
              label: "عابر بانک پشت و رو",
              key: 5,
            },
          ],
        },
        {
          key: "order_status",
          type: "select",
          label: "وضعیت پرداخت",
          options: [
            {
              label: "منتظر پرداخت",
              key: "wait_to_pay",
            },
            {
              label: "موفق",
              key: "success",
            },
            {
              label: "ناموفق",
              key: "error",
            },
            {
              label: "رها شده",
              key: "abandoned",
            },
          ],
        },
        {
          key: "product_status",
          type: "select",
          label: "وضعیت محصول",
          options: [
            {
              label: "دریافت طرح",
              key: "receiving_info",
            },
            {
              label: "بررسی طرح",
              key: "review_and_issue",
            },
            {
              label: "آماده ارسال",
              key: "ready_to_send",
            },
            {
              label: "ارسال شده",
              key: "posted",
            },
            {
              label: "بازگشت از پست",
              key: "returned",
            },
            {
              label: "فعال",
              key: "active",
            },
            {
              label: "غیر فعال",
              key: "inactive",
            },
            {
              label: "مسدود",
              key: "blocked",
            },
          ],
        },
      ],
      header: [
        {
          key: "client",
          label: "نام",
          type: "fullname",
        },
        {
          key: "mobile",
          label: "موبایل",
          type: "nested",
          objectKey: "client",
        },
        {
          key: "tracking_code",
          label: "کد پیگیری",
        },
        {
          key: "product_id",
          label: "نام محصول",
          type: "enum",
          enum: {
            1: "کارت هوشمند",
            2: "لینک هوشمند",
            3: "قاب هوشمند",
            4: "عابر بانک",
            5: "عابر بانک پشت و رو",
          },
        },
        {
          key: "status",
          label: "وضعیت پرداخت",
          type: "enum",
          enum: {
            wait_to_pay: "منتظر پرداخت",
            success: "موفق",
            error: "ناموفق",
            abandoned: "رها شده",
            receiving_info: "دریافت اطلاعات",
            review_and_issue: "بررسی و صدور",
            ready_to_send: "آماده ارسال",
            returned: "بازگشت از پست",
            posted: "ارسال شده",
            active: "فعال",
            inactive: "غیر فعال",
            blocked: "مسدود",
          },
        },
        {
          type: "rel",
          label: "وضعیت محصول",
        },
        {
          key: "created_at",
          label: "زمان ثبت",
          type: "date",
        },
      ],
    },
    {
      route: "/order/list/:id",
      show: false,
      type: "surface",
      surfaceType: "orderDetails",
    },
    {
      show: true,
      type: "form",
      label: "ثبت سفارش دستی",
      route: "/order/submit",
      api: {
        route: "/order/new_manual",
        needs: ["products", "faq_categories"],
      },
      form: [
        {
          key: "mobile",
          label: "شماره موبایل",
          help: "لطفا شماره موبایل را به صورت 09367360561 وارد کنید",
        },
        {
          key: "product_id",
          label: "محصول",
          need: "products",
          type: "select",
        },
        {
          key: "off_code",
          label: "کد تخفیف",
          noNeed: true,
          help: "توجه کنید در صورت وارد کردن کد تخفیف همکار فرش برای ایشان درآمد ثبت میشود.",
        },
      ],
    },
    {
      show: true,
      type: "form",
      label: "ثبت اکسل سفارشات دستی",
      route: "/order/excel",
      api: {
        route: "/order/posted_excel",
      },
      form: [
        {
          key: "excel",
          label: "فایل اکسل",
          type: "file",
        },
      ],
    },
    {
      type: "edit",
      label: "ثبت طرح دستی",
      route: "/order/submitOrder/:id",
      api: {
        route: "/order/submit_manual",
        show: {
          route: "/order",
        },
        needs: ["credit_color"],
      },
      needs: ["credit_color"],

      sort: (state) => {
        return {
          ...state,
          order_id: state.id,
          qrcode: state.qrcode || 0,
        };
      },
      form: [
        {
          key: "title",
          label: "عنوان عابر بانک",
          exist: {
            key: "product_id",
            value: 4,
          },
          noNeed: true,
        },
        {
          key: "title",
          label: "عنوان عابر بانک",
          exist: {
            key: "product_id",
            value: 5,
          },
          noNeed: true,
        },
        {
          key: "color_id",
          label: "رنگ عابر بانک",
          type: "select",
          need: "credit_color",
          exist: {
            key: "product_id",
            value: 4,
          },
          noNeed: true,
        },
        {
          key: "color_id",
          label: "رنگ عابر بانک",
          type: "select",
          need: "credit_color",
          exist: {
            key: "product_id",
            value: 5,
          },
          noNeed: true,
        },
        {
          key: "typeCard",
          label: "نوع طرح کارت",
          type: "select",
          exist: {
            key: "product_id",
            value: 1,
          },
          options: [
            {
              name: "طرح اختصاصی",
              key: "custom",
            },
            {
              name: "طرح پیش فرض",
              key: "default",
            },
          ],
          noNeed: true,
        },
        {
          key: "typeCredit",
          label: "نوع طرح عابر بانک",
          type: "select",
          exist: {
            key: "product_id",
            value: 4,
          },
          options: [
            {
              name: "طرح اختصاصی",
              key: "custom",
            },
            {
              name: "طرح پیش فرض",
              key: "default",
            },
          ],
          noNeed: true,
        },
        {
          noNeed: true,
          type: "selectImage",
          url: "credit",
          exist: {
            key: "typeCredit",
            value: "default",
          },
          key: "design_id",
        },
        {
          noNeed: true,
          key: "front_image",
          label: "روی کارت",
          type: "cropper",
          aspect: 8.5 / 5.5,
          exist: {
            key: "typeCredit",
            value: "custom",
          },
        },
        {
          noNeed: true,
          key: "front_image",
          label: "روی کارت",
          type: "cropper",
          aspect: 8.5 / 5.5,
          exist: {
            key: "product_id",
            value: 5,
          },
        },
        {
          noNeed: true,
          key: "back_image",
          label: "پشت کارت",
          type: "cropper",
          aspect: 8.5 / 5.5,
          exist: {
            key: "product_id",
            value: 5,
          },
        },

        {
          type: "toggle",
          label: "نیاز به qrcode",
          key: "qrcode",
          exist: {
            key: "product_id",
            value: 3,
          },
          noNeed: true,
        },
        {
          key: "qrcodePosition",
          label: "مکان qrcode",
          type: "select",
          noNeed: true,
          exist: {
            key: "qrcode",
            value: "true",
          },
          options: [
            {
              name: "پایین چپ",
              key: "bottom-left",
            },
            {
              name: "پایین راست",
              key: "bottom-right",
            },
            {
              key: "top-left",
              name: "بالا چپ",
            },
            {
              key: "top-right",
              name: "بالا راست",
            },
          ],
        },
        {
          key: "color",
          label: "رنگ قاب",
          type: "select",
          noNeed: true,
          exist: {
            key: "product_id",
            value: 3,
          },
          options: [
            {
              name: "سفید",
              key: "black",
            },
            {
              name: "مشکی",
              key: "white",
            },
          ],
        },
        {
          key: "size",
          label: "size",
          type: "select",
          noNeed: true,
          exist: {
            key: "product_id",
            value: 3,
          },
          options: [
            {
              name: "a4",
              key: "a4",
            },
            {
              name: "a5",
              key: "a5 ",
            },
          ],
        },
        {
          key: "typeFrame",
          label: "نوع طرح قاب",
          noNeed: true,
          type: "select",
          exist: {
            key: "product_id",
            value: 3,
          },
          options: [
            {
              name: "طرح اختصاصی",
              key: "custom",
            },
            {
              name: "طرح پیشفرض",
              key: "default",
            },
          ],
        },
        {
          noNeed: true,
          type: "selectImage",
          url: "frame",
          exist: {
            key: "typeFrame",
            value: "default",
          },
          key: "default",
        },
        {
          noNeed: true,
          key: "picture",
          label: "عکس قاب",
          type: "cropper",
          aspect: 10 / 14.12,
          exist: {
            key: "typeFrame",
            value: "custom",
          },
        },
        {
          noNeed: true,
          key: "persian_text",
          label: "متن فارسی",
          exist: {
            key: "typeCard",
            value: "default",
          },
        },
        {
          noNeed: true,
          key: "english_text",
          label: "متن انگلیسی",
          exist: {
            key: "typeCard",
            value: "default",
          },
        },
        {
          noNeed: true,
          key: "picture",
          label: "لوگو کارت",
          type: "cropper",
          aspect: 1 / 1,
          exist: {
            key: "typeCard",
            value: "default",
          },
        },
        {
          noNeed: true,
          key: "front_image",
          label: "روی کارت",
          type: "cropper",
          aspect: 8.5 / 5.5,
          exist: {
            key: "typeCard",
            value: "custom",
          },
        },
        {
          noNeed: true,
          key: "back_image",
          label: "پشت کارت",
          type: "cropper",
          aspect: 8.5 / 5.5,
          exist: {
            key: "typeCard",
            value: "custom",
          },
        },
      ],
    },
  ],
};
const faq = {
  label: " FAQ",
  icon: "",
  nested: true,
  access: ["admin", "editor"],
  icon: "fas fa-question",
  options: [
    {
      route: "/faq/create",
      type: "form",
      show: true,
      label: "ایجاد  FAQ",
      api: {
        route: "/faq",
        needs: ["products", "faq_categories"],
      },

      form: [
        {
          key: "question",
          type: "textarea",
          label: "سوال",
        },
        {
          key: "answer",
          type: "textarea",
          label: "پاسخ",
        },
        {
          key: "type_products",
          label: "برای",
          type: "select",
          options: [
            {
              name: "همه محصولات",
              key: "all",
            },
            {
              name: "محصول مشخص",
              key: "special",
            },
          ],
        },
        {
          key: "products_id",
          label: "محصول مورد نظر",
          exist: {
            key: "type_products",
            value: "special",
          },
          need: "products",
          noNeed: true,
          type: "select",
        },
        {
          key: "category_id",
          label: "دسته بندی",
          need: "faq_categories",
          type: "select",
        },
      ],
    },
    {
      route: "/faq/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/faq",
        show: {
          route: "/faq",
        },
        needs: ["products", "faq_categories"],
      },
      sortInint: (state) => {
        return {
          ...state,
        };
      },
      form: [
        {
          key: "question",
          type: "textarea",
          label: "سوال",
        },
        {
          key: "answer",
          type: "textarea",
          label: "پاسخ",
        },
        {
          key: "type_products",
          label: "برای",
          type: "select",
          options: [
            {
              name: "همه محصولات",
              key: "all",
            },
            {
              name: "محصول مشخص",
              key: "special",
            },
          ],
        },
        {
          key: "products_id",
          label: "محصول مورد نظر",
          exist: {
            key: "type_products",
            value: "special",
          },
          need: "products",
          noNeed: true,
          type: "select",
        },
        {
          key: "category_id",
          label: "دسته بندی",
          need: "faq_categories",
          type: "select",
        },
      ],
    },
    {
      route: "/faq/list",
      type: "table",
      show: true,
      label: "لیست  FAQ",
      api: {
        route: "/faq",
      },
      actions: [
        {
          type: "delete",
          label: "حذف faq",
          route: "/faq",
          key: "category_id",
        },
        {
          type: "show",
          label: "ویرایش faq",
          link: "/faq/update",
        },
      ],
      header: [
        {
          key: "question",
          label: "سوال",
        },
        {
          key: "answer",
          label: "پاسخ",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const comments = {
  label: "کامنت ها",
  icon: "",
  nested: true,
  access: ["admin", "editor"],
  icon: "fas fa-comment",
  options: [
    {
      route: "/comment/create",
      type: "form",
      show: true,
      label: "ایجاد کامنت",
      api: {
        route: "/comment",
        needs: ["products"],
      },
      form: [
        {
          key: "full_name",
          label: "نام و نام خانوادگی",
        },
        {
          key: "post",
          label: "سمت",
          noNeed: true,
        },
        {
          label: "متن",
          type: "textarea",
          key: "text",
        },
        {
          label: "محصول مربوط",
          key: "rel_id",
          type: "select",
          need: "products",
        },
        {
          key: "user_type",
          label: "نوع کاربر",
          type: "select",
          options: [
            {
              name: "ادمین",
              key: "admin",
            },
            {
              name: "کاربر",
              key: "client",
            },
          ],
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
          noNeed: true,
        },
      ],
    },
    {
      route: "/comment/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/comment",
        show: {
          route: "/comment",
          key: "category_id",
        },
        needs: ["products"],
      },
      sortInint: (state) => {
        return {
          image_alt: state?.photo || "",
          image: state?.photo || false,
          ...state,
        };
      },
      form: [
        {
          key: "full_name",
          label: "نام و نام خانوادگی",
        },
        {
          key: "post",
          label: "سمت",
          noNeed: true,
        },
        {
          label: "متن",
          type: "textarea",
          key: "text",
        },
        {
          label: "محصول مربوط",
          key: "rel_id",
          type: "select",
          need: "products",
        },
        {
          key: "user_type",
          label: "نوع کاربر",
          type: "select",
          options: [
            {
              name: "ادمین",
              key: "admin",
            },
            {
              name: "کاربر",
              key: "client",
            },
          ],
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
          noNeed: true,
        },
      ],
    },
    {
      route: "/comment/list",
      type: "table",
      show: true,
      label: "لیست  کامنت ها",
      api: {
        route: "/comment",
      },
      actions: [
        {
          type: "delete",
          route: "/comment",
        },
        {
          type: "show",
          label: "ویرایش کامنت",
          link: "/comment/update",
        },
      ],
      header: [
        {
          key: "full_name",
          label: "نام",
        },
        {
          key: "post",
          label: "سمت",
        },
        {
          key: "rel_type",
          label: "برای",
          type: "enum",
          enum: {
            Product: "محصول",
          },
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const landingGallery = {
  label: "عکس های صفحه اصلی",
  icon: "",
  nested: true,
  access: ["admin", "editor", "seo_manager"],
  icon: "fas fa-comment",
  options: [
    {
      route: "/landing_gallery/create",
      type: "form",
      show: true,
      label: "اضافه کردن عکس",
      api: {
        route: "/landing_gallery",
      },
      form: [
        {
          key: "alt",
          label: "alt",
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
        },
      ],
    },
    {
      route: "/landing_gallery/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/landing_gallery",
        show: {
          route: "/landing_gallery",
          key: "id",
        },
      },
      sortInint: (state) => {
        return {
          image_alt: state?.photo || "",
          image: state?.photo || false,
          ...state,
        };
      },
      form: [
        {
          key: "alt",
          label: "alt",
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
        },
      ],
    },
    {
      route: "/landing_gallery/list",
      type: "table",
      show: true,
      label: "لیست  عکس ها",
      api: {
        route: "/landing_gallery",
      },
      actions: [
        {
          type: "delete",
          route: "/landing_gallery",
        },
        {
          type: "show",
          label: "ویرایش عکس",
          link: "/landing_gallery/update",
        },
      ],
      header: [
        {
          key: "photo",
          label: "لینک عکس",
        },
        {
          key: "alt",
          label: "alt",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const crm = {
  label: "CRM",
  icon: "fas fa-chart-line",
  access: ["admin", "sales_manager", "operator"],
  nested: true,
  options: [
    {
      route: "/crm/list",
      type: "table",
      selectAble: {
        status: true,
        type: "btn",
        text: "تخصیص داده به کارشناس",
        route: "/crm/add_sellers_manual",
        statement: {
          key: "status",
          value: ["pending", "try_again", "canceled", "unsuccessful"],
        },
        subRoute: "/crm/add_sellers_specific_data",
      },
      filters: [
        {
          key: "status",
          type: "select",
          options: [
            {
              label: "بدون کارشناس",
              key: "pending",
            },
            {
              label: "منتظر بررسی کارشناس",
              key: "awaiting_review",
            },
            {
              label: "در حال بررسی",
              key: "start_procses",
            },
            {
              label: "بررسی متوقف شده",
              key: "wait_procses",
            },
            {
              label: "موفق",
              key: "successful",
            },
            {
              label: "ناموفق",
              key: "unsuccessful",
            },
            {
              label: "تلاش مجدد",
              key: "try_again",
            },
            {
              label: "رد شده",
              key: "canceled",
            },
          ],
        },
        {
          route: "/crm/add_seller_automat",
          text: "تخصیص همه ی داده ها به صورت رندوم",
          type: "btn",
          statement: {
            key: "status",
            value: "pending",
          },
        },
      ],
      show: true,
      label: "لیست",
      api: {
        route: "/crm/list",
      },
      actions: [
        {
          type: "delete",
          label: "حذف",
          route: "/crm/delete_crm",
          key: "id",
        },
        {
          type: "select",
          label: "تغییر وضعیت",
          route: "/crm/change_status",
          key: "crm_id",
          objectKey: "status",
          options: [
            {
              label: "بدون کارشناس",
              key: "pending",
            },
            {
              label: "منتظر بررسی کارشناس",
              key: "awaiting_review",
            },
            {
              label: "در حال بررسی",
              key: "start_process",
            },
            {
              label: "بررسی متوقف شده",
              key: "wait_process",
            },
            {
              label: "موفق",
              key: "successful",
            },
            {
              label: "ناموفق",
              key: "unsuccessful",
            },
            {
              label: "رد شده",
              key: "canceled",
            },
            {
              label: "تلاش مجدد",
              key: "try_again",
            },
          ],
        },

        {
          type: "show",
          link: "/reports",
          label: "گزارشات",
        },
      ],
      header: [
        {
          key: "full_name",
          label: "نام",
        },

        {
          key: "mobile",
          label: "موبایل",
        },

        {
          key: "status",
          label: "وضعیت",
          type: "enum",
          enum: {
            awaiting_review: "منتظر بررسی کارشناس",
            start_process: "در حال بررسی",
            wait_process: "بررسی متوقف شده",
            successful: "موفق",
            unsuccessful: "ناموفق",
            try_again: "رد شده",
            pending: "بدون کارشناس",
            try_again: "تلاش مجدد",
          },
          default: "user",
        },
        {
          key: "fullname",
          label: "نام کارشناس",
          type: "fullnameSeller",
          objectKey: "seller",
        },
        {
          key: "change_status_date",
          label: "آخرین تغییر وضعیت",
          type: "date",
        },
        {
          key: "created_at",
          label: "تاریخ ایجاد",
          type: "date",
        },
      ],
    },
    {
      route: "/crm/add-form",
      type: "form",
      show: true,
      label: "ثبت دستی",
      api: {
        route: "/crm/import_data",
      },
      form: [
        {
          key: "full_name",
          label: "نام",
        },

        {
          key: "mobile",
          label: "موبایل",
        },
      ],
    },
    {
      route: "/crm/add-file",
      type: "form",
      show: true,
      label: "ارسال فایل",
      access: ["admin", "sales_manager", "operator"],
      api: {
        route: "/crm/import_file",
      },
      form: [
        {
          key: "file",
          label: "فایل اکسل",
          type: "file",
        },
      ],
    },
    {
      label: "",
      show: false,
      type: "surface",
      surfaceType: "reports",
      key: "crm_id",
      route: "/reports/:id",
      api: {
        route: "/seller/crm/show_reports",
      },
    },
  ],
};
const storeDataSeller = {
  label: "CRM",
  icon: "fas fa-chart-line",
  access: ["seller"],
  nested: false,
  route: "/crm/add-form",
  label: "ثبت دستی",
  options: [
    {
      route: "/crm/add-form",
      type: "form",
      show: true,
      label: "ثبت دستی",
      api: {
        route: "/crm/import_data",
      },
      form: [
        {
          key: "full_name",
          label: "نام",
        },

        {
          key: "mobile",
          label: "موبایل",
        },
      ],
    },
  ],
};
const myData = {
  label: "لیست داده ها من",
  route: "/list",
  nested: false,
  icon: "fas fa-chart-line",
  access: ["seller"],

  options: [
    {
      type: "table",
      route: "/list",
      api: {
        route: "/seller/crm/list_data",
      },
      filters: [
        {
          key: "status",
          type: "select",
          options: [
            {
              label: "منتظر بررسی کارشناس",
              key: "awaiting_review",
            },
            {
              label: "در حال بررسی",
              key: "start_procses",
            },
            {
              label: "بررسی متوقف شده",
              key: "wait_procses",
            },
            {
              label: "موفق",
              key: "successful",
            },
            {
              label: "ناموفق",
              key: "Unsuccessful",
            },
            {
              label: "رد شده",
              key: "Canceled",
            },
          ],
        },
      ],
      actions: [
        {
          type: "select",
          label: "تغییر وضعیت",
          route: "/seller/crm/change_status",
          key: "crm_id",
          objectKey: "status",
          options: [
            {
              label: "منتظر بررسی کارشناس",
              key: "awaiting_review",
            },
            {
              label: "در حال بررسی",
              key: "start_process",
            },
            {
              label: "بررسی متوقف شده",
              key: "wait_process",
            },
            {
              label: "موفق",
              key: "successful",
            },
            {
              label: "ناموفق",
              key: "unsuccessful",
            },
            {
              label: "رد شده",
              key: "canceled",
            },
          ],
        },
        {
          type: "report",
          label: "ثبت گزارش",
          route: "/seller/crm/add_report",
          key: "crm_id",
          objectKey: "note",
        },
        { type: "show", link: "/reports", label: "گزارشات" },
      ],
      header: [
        {
          key: "full_name",
          label: "نام",
        },

        {
          key: "mobile",
          label: "موبایل",
        },

        {
          key: "status",
          label: "وضعیت",
          type: "enum",
          enum: {
            noـexpert: "بدون کارشناس",
            awaiting_review: "منتظر بررسی کارشناس",
            start_procses: "در حال بررسی",
            wait_procses: "بررسی متوقف شده",
            successful: "موفق",
            Unsuccessful: "ناموفق",
            Canceled: "رد شده",
          },
          default: "user",
        },
        {
          key: "change_status_date",
          label: "آخرین تغییر وضعیت",
          type: "date",
        },
        {
          key: "created_at",
          label: "تاریخ ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
    {
      label: "",
      show: false,
      type: "surface",
      surfaceType: "reports",
      key: "crm_id",
      route: "/reports/:id",
      api: {
        route: "/seller/crm/show_reports",
      },
    },
  ],
};
const our_team = {
  label: "تیم ما",
  icon: "",
  nested: true,
  access: ["admin"],
  icon: "fas fas fa-people-arrows",
  options: [
    {
      route: "/team/create",
      type: "form",
      show: true,
      label: "ایجاد تیم",
      api: {
        route: "/our_team",
      },
      form: [
        {
          key: "full_name",
          label: "نام و نام خانوادگی",
        },
        {
          key: "internal_number",
          label: "شماره داخلی",
        },
        {
          key: "post",
          label: "پست مربوط",
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
        },
      ],
    },
    {
      route: "/team/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/our_team",
        show: {
          route: "/our_team",
        },
      },
      sortInint: (state) => {
        return {
          image_alt: state?.photo || "",
          image: state?.photo || false,
          ...state,
        };
      },
      form: [
        {
          key: "full_name",
          label: "نام و نام خانوادگی",
        },
        {
          key: "internal_number",
          label: "شماره داخلی",
        },
        {
          key: "post",
          label: "پست مربوط",
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
        },
      ],
    },
    {
      route: "/team/list",
      type: "table",
      show: true,
      label: "لیست  تیم ما",
      api: {
        route: "/our_team",
      },
      actions: [
        {
          type: "delete",
          route: "/our_team",
        },
        {
          type: "show",
          label: "ویرایش تیم",
          link: "/team/update",
        },
      ],
      header: [
        {
          key: "full_name",
          label: "نام",
        },
        {
          key: "post",
          label: "سمت",
        },
        {
          key: "internal_number",
          label: "شماره داخلی",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const shopSlider = {
  label: "اسلایدر صفحه شاپ",
  icon: "",
  nested: true,
  access: ["admin", "editor"],
  icon: "fas fa-shopping-cart",
  options: [
    {
      route: "/slidre/create",
      type: "form",
      show: true,
      label: "ایجاد اسلاید",
      api: {
        route: "/shop_slider",
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "description",
          label: "توضیحات",
          type: "textarea",
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
        },
        {
          key: "first_link",
          label: "لینک اول",
        },
        {
          key: "first_link_type",
          label: "نوع لینک اول",
          type: "select",
          options: [
            {
              name: "داخلی",
              key: "local",
            },
            {
              name: "خارجی",
              key: "global",
            },
          ],
        },
        {
          key: "second_link",
          label: "لینک دوم",
        },
        {
          key: "second_link_type",
          label: "نوع لینک دوم",
          type: "select",
          options: [
            {
              name: "داخلی",
              key: "local",
            },
            {
              name: "خارجی",
              key: "global",
            },
          ],
        },
      ],
    },
    {
      route: "/slider/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/shop_slider",
        show: {
          route: "/shop_slider",
        },
      },
      sortInint: (state) => {
        if (state?.photo?.length > 0) {
          return {
            ...state,
            photo: state.photo[0].url,
            first_link_type: state?.first_link?.type,
            first_link: state?.first_link?.url,
            second_link_type: state?.second_link?.type,
            second_link: state?.second_link?.url,
          };
        } else {
          return {
            ...state,

            first_link_type: state?.first_link?.type,
            first_link: state?.first_link?.url,
            second_link_type: state?.second_link?.type,
            second_link: state?.second_link?.url,
          };
        }
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "description",
          label: "توضیحات",
          type: "textarea",
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "images",
        },
        {
          key: "first_link",
          label: "لینک اول",
        },
        {
          key: "first_link_type",
          label: "نوع لینک اول",
          type: "select",
          options: [
            {
              name: "داخلی",
              key: "local",
            },
            {
              name: "خارجی",
              key: "global",
            },
          ],
        },
        {
          key: "second_link",
          label: "لینک دوم",
        },
        {
          key: "second_link_type",
          label: "نوع لینک دوم",
          type: "select",
          options: [
            {
              name: "داخلی",
              key: "local",
            },
            {
              name: "خارجی",
              key: "global",
            },
          ],
        },
      ],
    },
    {
      route: "/shop_slider/list",
      type: "table",
      show: true,
      label: "لیست  اسلاید ها",
      api: {
        route: "/shop_slider",
      },
      actions: [
        {
          type: "delete",
          route: "/shop_slider",
        },
        {
          type: "show",
          label: "ویرایش اسلاید",
          link: "/slider/update",
        },
      ],
      header: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const products = {
  label: "محصولات",
  icon: "",
  nested: true,
  access: ["admin"],
  icon: "fas fab fa-shopify",
  options: [
    {
      route: "/product/list",
      type: "table",
      show: true,
      label: "لیست  محصولات",
      api: {
        route: "/product",
      },
      actions: [
        {
          type: "show",
          label: "ویرایش اسلاید",
          link: "/product/update",
        },
        {
          type: "gift",
          route: "/product/off",
          label: "ثبت قیمت دوم",
          key: "id",
        },
      ],
      header: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
    {
      route: "/product/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش محصول",
      api: {
        route: "/product",
        show: {
          route: "/product",
        },
      },
      sortInint: (state) => {
        if (
          state.photo &&
          Array.isArray(state.photo) &&
          state.photo.length > 0
        ) {
          return {
            ...state,
            photo: state?.photo[0].url,
          };
        } else {
          return {
            ...state,
          };
        }
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "price",
          label: "قیمت",
        },
        {
          key: "description",
          label: "توضیحات",
          type: "textarea",
        },
        {
          key: "photo",
          label: "تصویر ",
          type: "multiImages",
        },
      ],
    },
  ],
};
const schema = {
  label: "اسکیما",
  icon: "",
  nested: true,
  access: ["admin", "editor"],
  icon: "fas fa-pager",
  options: [
    {
      route: "/schema/list",
      type: "table",
      show: true,
      label: "لیست  اسکیما ها",
      api: {
        route: "/json_ld",
      },
      actions: [
        {
          type: "show",
          label: "ویرایش اسلاید",
          link: "/schema/update",
        },
      ],
      header: [
        {
          key: "page",
          label: "صفحه",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
    {
      route: "/schema/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش اسکیما",
      customValidate: (state) => {
        if (isJson(state.json)) {
          return true;
        } else {
          toast.error(
            "json درست نیست برای جلوگیری از به فنا رفتن سامانه json درست بزار"
          );
        }
      },
      api: {
        route: "/json_ld",
        show: {
          route: "/json_ld",
        },
      },
      sortInint: (state) => {
        return {
          image_alt: state?.photo || "",
          image: state?.photo || false,
          ...state,
        };
      },
      form: [
        {
          key: "title",
          label: "عنوان صفحه",
        },
        {
          key: "description",
          label: "توضیحات صفحه",
        },
        {
          key: "json",
          label: "json",
          type: "json",
        },
      ],
    },
  ],
};
const profile = {
  label: "پروفایل",
  icon: "fas fa-user-edit",
  route: "/profile",
  nested: false,
  access: [
    "admin",
    "operator",
    "sales_manager",
    "seller",
    "seo_manager",
    "editor",
  ],
  options: [
    {
      show: true,
      route: "/profile",
      type: "surface",
      surfaceType: "profile",
      access: [
        "admin",
        "operator",
        "sales_manager",
        "seller",
        "seo_manager",
        "editor",
      ],
    },
  ],
};
const frameImage = {
  label: "عکس های پیشفرض قاب",
  icon: "",
  nested: true,
  access: ["admin"],
  icon: "fas fa-comment",
  options: [
    {
      route: "/frame_image/create",
      type: "form",
      show: true,
      label: "اضافه کردن عکس",
      api: {
        route: "/frame_image",
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "svg",
          label: "عکس(svg or webp)",
          type: "images",
        },
        {
          key: "print",
          label: "عکس با کیفیت پرینت",
          type: "images",
        },
      ],
    },
    {
      route: "/frame_image/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/frame_image",
        show: {
          route: "/frame_image",
          key: "id",
        },
      },
      sortInint: (state) => {
        return {
          image_alt: state?.photo || "",
          image: state?.photo || false,
          ...state,
        };
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "svg",
          label: "عکس(svg)",
          type: "images",
        },
        {
          key: "print",
          label: "عکس با کیفیت پرینت",
          type: "images",
        },
      ],
    },
    {
      route: "/frame_image/list",
      type: "table",
      show: true,
      label: "لیست  عکس ها",
      api: {
        route: "/frame_image",
      },
      actions: [
        {
          type: "delete",
          route: "/frame_image",
        },
        {
          type: "show",
          label: "ویرایش عکس",
          link: "/frame_image/update",
        },
      ],
      header: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "print",
          label: "لینک عکس",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};
const discount = {
  label: "مدیریت تخفیف ها",
  icon: "fas fa-gift",
  access: ["admin"],
  nested: true,
  options: [
    {
      route: "/discount/create",
      type: "form",
      show: true,
      label: "ایجاد تخفیف",
      api: {
        needs: ["products"],
        route: "/discount_code",
      },
      box: {
        route: "/discount/list",
        title: "تخفیف ها",
      },
      sort: (state) => {
        return { ...state, product_id: state?.product_id?.map((e) => e.value) };
      },
      form: [
        {
          key: "code",
          label: "کد تخفیف",
        },
        {
          key: "type_discount",
          label: "نوع تخفیف",
          type: "select",
          options: [
            {
              name: "درصدی",
              key: "percentage",
            },
            {
              name: "پولی",
              key: "money",
            },
          ],
        },
        {
          key: "amount_discount",
          label: "میزان تخفیف",
          effect: {
            percentage: {
              name: "درصد",
            },
            money: {
              name: "تومان",
            },
          },
        },
        {
          key: "max_offer_amount",
          label: "حداکثر مقدار تخفیف(تومان)",
          exist: {
            key: "type_discount",
            value: "percentage",
          },
          noNeed: true,
        },
        {
          key: "product_type",
          label: "برای",
          type: "select",
          options: [
            {
              name: "همه مصولات ",
              key: "all",
            },
            {
              name: "محصول خاص",
              key: "special",
            },
          ],
        },
        {
          key: "product_id",
          label: "محصول",
          type: "multiselect",
          need: "products",
          exist: {
            key: "product_type",
            value: "special",
          },
          noNeed: true,
        },
        {
          key: "count_allowed",
          label: "تعداد مجاز استفاده",
        },
        {
          key: "on_second_price",
          type: "toggle",
          label: "اعمال تخفیف بر روی قیمت جشنواره",
          noNeed: true,
        },
        {
          key: ["pro_time", "exp_time"],
          label: "تاریخ شروع و پایان",
          type: "date",
        },
      ],
    },
    {
      route: "/discount/list",
      type: "table",
      show: true,
      label: "تخفیف ها",
      api: {
        route: "/discount_code",
      },
      actions: [
        {
          type: "delete",
          label: "حذف تخفیف",
          route: "/discount_code",
          key: "discount_id",
        },
        {
          type: "show",
          label: "ویرایش تخفیف",
          link: "/discount/update",
        },
      ],
      header: [
        {
          key: "code",
          label: "کد تخفیف",
        },
        {
          key: "type_discount",
          label: "نوع تخفیف",
          type: "enum",
          enum: {
            percentage: "درصدی",
            money: "پولی",
          },
        },
        {
          key: "amount_discount",
          label: "مقدار",
        },
        {
          key: "used_count",
          label: "تعداد استفاده",
        },
        {
          key: "pro_time",
          label: "تاریخ شروع",
        },
        {
          key: "exp_time",
          label: "تاریخ پایان",
        },
      ],
    },
    {
      route: "/discount/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      sort: (state) => {
        return { ...state, product_id: state?.product_id?.map((e) => e.value) };
      },
      sortInint: (data) => {
        return {
          ...data,
          product_id: data?.item.map((e) => {
            return { label: e.item.label, value: e.item.value };
          }),
        };
      },
      api: {
        route: "/discount_code",
        show: {
          route: "/discount_code",
        },
        needs: ["products"],
      },
      form: [
        {
          key: "code",
          label: "کد تخفیف",
        },
        {
          key: "type_discount",
          label: "نوع تخفیف",
          type: "select",
          options: [
            {
              name: "درصدی",
              key: "percentage",
            },
            {
              name: "پولی",
              key: "money",
            },
          ],
        },
        {
          key: "amount_discount",
          label: "میزان تخفیف",
          effect: {
            percentage: {
              name: "درصد",
            },
            money: {
              name: "تومان",
            },
          },
        },
        {
          key: "max_offer_amount",
          label: "حداکثر مقدار تخفیف(تومان)",
          exist: {
            key: "type_discount",
            value: "percentage",
          },
          noNeed: true,
        },
        {
          key: "product_type",
          label: "برای",
          type: "select",
          options: [
            {
              name: "همه مصولات ",
              key: "all",
            },
            {
              name: "محصول خاص",
              key: "special",
            },
          ],
        },
        {
          key: "product_id",
          label: " محصول",
          type: "multiselect",
          need: "products",
          exist: {
            key: "product_type",
            value: "special",
          },
          noNeed: true,
        },
        {
          key: "count_allowed",
          label: "تعداد مجاز استفاده",
        },
        {
          key: "on_second_price",
          type: "toggle",
          label: "اعمال تخفیف بر روی قیمت جشنواره",
          noNeed: true,
        },
        {
          key: ["pro_time", "exp_time"],
          label: "تاریخ شروع و پایان",
          type: "date",
        },
      ],
    },
  ],
};
const colleagues = {
  label: "همکاران فروش",
  nested: true,
  icon: "far fa-user",
  access: ["admin"],
  options: [
    {
      route: "/marketer/create",
      type: "form",
      show: true,
      label: "ایجاد همکار فروش",
      sort: (state) => {
        return { ...state, product_id: state?.product_id?.map((e) => e.value) };
      },
      api: {
        route: "/marketer_details/user",
        needs: ["products"],
      },
      box: {
        route: "/marketer/list",
        title: "لیست همکاران فروش",
      },
      form: [
        {
          key: "first_name",
          label: "نام",
          noNeed: true,
        },
        {
          key: "last_name",
          label: "نام خانوادگی",
          noNeed: true,
        },
        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "email",
          noNeed: true,
          label: "ایمیل",
        },
        {
          key: "national_id",
          noNeed: true,
          label: "کد ملی",
        },
        {
          noNeed: true,
          key: "photo",
          label: "عکس پروفایل",
          type: "images",
        },
        {
          key: "commission_type",
          label: "نوع مشارکت",
          type: "select",
          options: [
            {
              name: "اتوماتیک",
              key: "auto",
            },
            {
              name: "درصد خاص",
              key: "custom",
            },
          ],
        },
        {
          key: "percentage",
          label: "درصد مشارکت",
          exist: {
            key: "commission_type",
            value: "custom",
          },
          noNeed: true,
        },
        {
          key: "bank_number",
          label: "شماره کارت",
        },
        {
          key: "code",
          label: "کد تخفیف",
        },
        {
          key: "type_discount",
          label: "نوع تخفیف",
          type: "select",
          options: [
            {
              name: "درصدی",
              key: "percentage",
            },
            {
              name: "پولی",
              key: "money",
            },
          ],
        },
        {
          key: "amount_discount",
          label: "میزان تخفیف",
          effect: {
            percentage: {
              name: "درصد",
            },
            money: {
              name: "تومان",
            },
          },
        },
        {
          key: "max_offer_amount",
          label: "حداکثر مقدار تخفیف(تومان)",
          exist: {
            key: "type_discount",
            value: "percentage",
          },
          noNeed: true,
        },
        {
          key: "product_type",
          label: "برای",
          type: "select",
          options: [
            {
              name: "همه مصولات ",
              key: "all",
            },
            {
              name: "محصول خاص",
              key: "special",
            },
          ],
        },
        {
          key: "product_id",
          label: "محصول",
          type: "multiselect",
          need: "products",
          exist: {
            key: "product_type",
            value: "special",
          },
          noNeed: true,
        },
        {
          key: "count_allowed",
          label: "تعداد مجاز استفاده",
        },
        {
          key: "on_second_price",
          type: "toggle",
          label: "اعمال تخفیف بر روی قیمت جشنواره",
          noNeed: true,
        },
      ],
    },
    {
      route: "/marketer/list",
      type: "table",
      show: true,
      label: "لیست همکاران فروش",
      api: {
        route: "/marketer_details/user",
      },
      sort: (data) => {
        return {
          ...data,
          data: data.data.map((ele) => {
            return { ...ele, discount: ele.marketer_details.discount };
          }),
        };
      },
      actions: [
        {
          type: "delete",
          label: "حذف",
          route: "/marketer_details/user",
        },
        {
          type: "show",
          label: "مشاهده",
          link: "/marketer/update",
        },
        {
          type: "access",
          label: "generate password",
        },
        {
          type: "show_w_query",
          key: "code",
          objectKey: "discount",
          query: "off_code",
          link: "/order/list",
          label: "مشاهده سفارشات این کاربر",
        },
      ],
      header: [
        {
          key: "client",
          label: "نام",
          type: "fullname",
        },
        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "code",
          label: "کد تخفیف",
          type: "nested",
          objectKey: "discount",
        },
        {
          key: "used_count",
          label: "تعداد فروش موفق",
          type: "nested",
          objectKey: "discount",
        },
        {
          key: "created_at",
          label: "تاریخ ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
    {
      route: "/marketer/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش همکار فروش",
      box: {
        title: "لیست همکاران فروش",
        route: "/marketer/list",
      },
      sort: (state) => {
        return { ...state, product_id: state?.product_id?.map((e) => e.value) };
      },
      api: {
        route: "/marketer_details/user",
        show: {
          route: "/marketer_details/user",
          key: "user_id",
        },
        needs: ["products"],
      },

      sortInint: (state) => {
        console.log(state);
        if (state?.photo && state?.photo[0]) {
          return {
            ...state,
            photo: state?.photo[0] || "",
            user_id: state.id,
          };
        } else {
          return {
            ...state,
            user_id: state.id,
            photo: "",
          };
        }
      },
      form: [
        {
          key: "first_name",
          label: "نام",
          noNeed: true,
        },
        {
          key: "last_name",
          label: "نام خانوادگی",
          noNeed: true,
        },

        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "email",
          noNeed: true,
          label: "ایمیل",
        },
        {
          key: "national_id",
          noNeed: true,
          label: "کد ملی",
        },
        {
          noNeed: true,
          key: "photo",
          label: "عکس پروفایل",
          type: "images",
        },
        {
          key: "commission_type",
          label: "نوع مشارکت",
          type: "select",
          options: [
            {
              name: "اتوماتیک",
              key: "auto",
            },
            {
              name: "درصد خاص",
              key: "custom",
            },
          ],
        },
        {
          key: "percentage",
          label: "درصد مشارکت",
          exist: {
            key: "commission_type",
            value: "custom",
          },
          noNeed: true,
        },
        {
          key: "bank_number",
          label: "شماره کارت",
        },
        {
          key: "code",
          label: "کد تخفیف",
        },
        {
          key: "type_discount",
          label: "نوع تخفیف",
          type: "select",
          options: [
            {
              name: "درصدی",
              key: "percentage",
            },
            {
              name: "پولی",
              key: "money",
            },
          ],
        },
        {
          key: "amount_discount",
          label: "میزان تخفیف",
          effect: {
            percentage: {
              name: "درصد",
            },
            money: {
              name: "تومان",
            },
          },
        },
        {
          key: "max_offer_amount",
          label: "حداکثر مقدار تخفیف(تومان)",
          exist: {
            key: "type_discount",
            value: "percentage",
          },
          noNeed: true,
        },
        {
          key: "product_type",
          label: "برای",
          type: "select",
          options: [
            {
              name: "همه مصولات ",
              key: "all",
            },
            {
              name: "محصول خاص",
              key: "special",
            },
          ],
        },
        {
          key: "product_id",
          label: "محصول",
          type: "multiselect",
          need: "products",
          exist: {
            key: "product_type",
            value: "special",
          },
          noNeed: true,
        },
        {
          key: "count_allowed",
          label: "تعداد مجاز استفاده",
        },
        {
          key: "on_second_price",
          type: "toggle",
          label: "اعمال تخفیف بر روی قیمت جشنواره",
          noNeed: true,
        },
      ],
    },
    {
      route: "/collaborations",
      type: "table",
      show: true,
      label: "درخواست همکاری",
      api: {
        route: "/cooperation_request",
      },
      actions: [
        {
          type: "updateConfirm",
          label: "تایید",
          route: "/marketer/create",
        },
        {
          type: "delete",
          label: "حذف",
          route: "/cooperation_request",
        },
      ],
      header: [
        {
          key: "full_name",
          label: "نام و نام خانوادگی",
        },
        {
          key: "mobile",
          label: "موبایل",
          type: "nested",
          objectKey: "user",
        },
        {
          key: "title",
          label: "استان",
          type: "nested",
          objectKey: "province",
        },
        {
          key: "title",
          label: "شهرستان",
          type: "nested",
          objectKey: "city",
        },
        {
          type: "date",
          key: "created_at",
          label: "تاریخ ثبت",
        },
      ],
    },
    {
      route: "/collaborations/withdraw",
      type: "table",
      show: true,
      label: "درخواست برداشت درآمد",
      api: {
        route: "/marketer_withdraw",
      },
      actions: [
        {
          type: "show",
          label: "تایید",
          link: "/collaborations/withdraw",
        },
        {
          type: "delete",
          label: "حذف",
          route: "/marketer_withdraw",
        },
      ],
      filters: [
        {
          key: "status",
          type: "select",
          options: [
            {
              label: "در حال بررسی",
              key: "in_process",
            },
            {
              label: "رد شده",
              key: "error",
            },
            {
              label: "تایید شده",
              key: "success",
            },
          ],
        },
      ],
      header: [
        {
          key: "first_name",
          label: "نام",
          type: "nested",
          objectKey: "user",
        },
        {
          key: "last_name",
          label: "نام خانوادگی",
          type: "nested",
          objectKey: "user",
        },
        {
          key: "mobile",
          label: "موبایل",
          type: "nested",
          objectKey: "user",
        },
        {
          key: "status",
          label: "نوع",
          type: "enum",
          enum: {
            in_process: "منتظر بررسی",
            error: "رد شده",
            success: "تایید شده",
          },
        },
        {
          key: "last_total_income",
          label: "کل درآمد تا این لحظه",
        },
        {
          key: "price",
          label: "مبلغ درخواستی",
        },
        {
          type: "date",
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          type: "date",
          key: "updated_at",
          label: "تاریخ تغییر وضعیت",
        },
      ],
    },
    {
      route: "/collaborations/withdraw/:id",
      type: "edit",
      show: false,
      label: "ثبت وضعیت درخواست",
      box: {
        title: "لیست درخواست ها",
        route: "/collaborations/withdraw",
      },
      api: {
        route: "/marketer_withdraw",
        show: {
          route: "/marketer_withdraw",
          key: "request_id",
        },
      },
      form: [
        {
          label: "توضیحات",
          type: "textarea",
          key: "result",
        },
        {
          key: "status",
          label: "وضعیت",
          type: "select",
          options: [
            {
              name: "رد کردن",
              key: "error",
            },
            {
              name: "تایید کردن",
              key: "success",
            },
          ],
        },
      ],
    },
  ],
};
const creditDesign = {
  label: "طرح های عابربانک",
  icon: "",
  nested: true,
  access: ["admin"],
  icon: "fas fa-comment",
  options: [
    {
      route: "/credit_design/create",
      type: "form",
      show: true,
      label: "اضافه کردن عکس",
      api: {
        route: "/credit_temp_design",
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "photo",
          label: "عکس(svg or webp)",
          type: "images",
        },
        {
          key: "photo_real",
          label: "عکس با کیفیت پرینت",
          type: "images",
        },
      ],
    },
    {
      route: "/credit_design/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/credit_temp_design",
        show: {
          route: "/credit_temp_design",
          key: "id",
        },
      },
      sortInint: (state) => {
        return {
          image_alt: state?.photo || "",
          image: state?.photo || false,
          ...state,
        };
      },
      form: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "photo",
          label: "عکس(svg)",
          type: "images",
        },
        {
          key: "photo_real",
          label: "عکس با کیفیت پرینت",
          type: "images",
        },
      ],
    },
    {
      route: "/credit_design/list",
      type: "table",
      show: true,
      label: "لیست  عکس ها",
      api: {
        route: "/credit_temp_design",
      },
      actions: [
        {
          type: "delete",
          route: "/credit_temp_design",
        },
        {
          type: "show",
          label: "ویرایش عکس",
          link: "/credit_design/update",
        },
      ],
      header: [
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "photo_real",
          label: "لینک عکس",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};

const creditColor = {
  label: "رنگ های عابربانک",
  icon: "",
  nested: true,
  access: ["admin"],
  icon: "fas fa-comment",
  options: [
    {
      route: "/credit_color/create",
      type: "form",
      show: true,
      label: "اضافه کردن رنگ",
      api: {
        route: "/credit_color",
      },
      form: [
        {
          key: "color",
          label: " عنوان رنگ",
        },
      ],
    },
    {
      route: "/credit_color/update/:id",
      type: "edit",
      show: false,
      label: "ویرایش ",
      api: {
        route: "/credit_color",
        show: {
          route: "/credit_color",
          key: "id",
        },
      },
      form: [
        {
          key: "color",
          label: "عنوان رنگ ",
        },
      ],
    },
    {
      route: "/credit_color/list",
      type: "table",
      show: true,
      label: "لیست  رمگ ها",
      api: {
        route: "/credit_color",
      },
      actions: [
        {
          type: "delete",
          route: "/credit_color",
        },
        {
          type: "show",
          label: "ویرایش عکس",
          link: "/credit_color/update",
        },
      ],
      header: [
        {
          key: "color",
          label: "عنوان رنگ",
        },
        {
          key: "created_at",
          label: "زمان ایجاد",
          type: "date",
        },
        {
          key: "updated_at",
          type: "date",
          label: "آخرین ویرایش",
        },
      ],
    },
  ],
};

////////////////////////////////////////////////////////////////////////////////

export const stracture = {
  pages: [
    dashboard,
    users,
    orders,
    categoriesFaq,
    faq,
    crm,
    products,
    discount,
    colleagues,
    schema,
    shopSlider,
    landingGallery,
    frameImage,
    creditDesign,
    creditColor,
    categories,
    comments,
    storeDataSeller,
    myData,
    our_team,
    blogs,
    settings,
    profile,

    // test,
  ],
};
const Panel = () => {
  const renderRoute = (option) => {
    switch (option.type) {
      case "form":
        return (
          <Route
            path={option.route}
            element={
              <Layout stracture={stracture}>
                <Form options={option} />
              </Layout>
            }
          />
        );
      case "form-report":
        return (
          <Route
            path={option.route}
            element={
              <Layout stracture={stracture}>
                <Form
                  options={option}
                  renderComponent={() => {
                    return <Reportazh />;
                  }}
                />
              </Layout>
            }
          />
        );
      case "edit":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Form options={option} edit={true} />
              </Layout>
            }
          />
        );
      case "table":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Table options={option} />
              </Layout>
            }
          />
        );
      case "dashboard":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
        );
      case "ticketing":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Support />
              </Layout>
            }
          />
        );
      case "surface":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Surfaces option={option} />
              </Layout>
            }
          />
        );
      case "test":
        return (
          <Route
            path="/test"
            element={
              <Layout>
                <FormV1 />
              </Layout>
            }
          />
        );

      default:
        break;
    }
  };
  const {
    state: { user },
  } = useMainContext();
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        {stracture &&
          stracture?.pages.map((page) => {
            return page?.options.map((option) => {
              if (option) {
                return renderRoute(option);
              }
            });
          })}
      </Routes>
    </>
  );
};

export default Panel;
const Reportazh = () => {
  return (
    <>
      <div className="mt-2"></div>
      <Table
        options={{
          route: "/tickets",
          type: "table",
          show: true,
          label: "گزارش سابقه ثبت اعلامیه نرخ مقاطع فولادی",
          api: {
            route: "/products/reportExcel",
          },
          // dashboard: true,≈

          header: [
            {
              key: "file",
              label: "ارسال شده",
              type: "static",
              value: "مدیریت سامانه",
            },

            {
              key: "created_at",
              label: "تاریخ ثبت",
              type: "exact-date",
            },
            {
              key: "file",
              label: "دریافت فایل",
              type: "link",
            },
          ],
        }}
      />
    </>
  );
  const { post } = useMainContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const get = async () => {
    setLoading(true);
    const { data } = await post("/products/reportExcel", { limit: 100000 });
    setLoading(false);
    setData(data);
  };
  useEffect(() => {
    get();
  }, []);
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center my-5">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
    return <></>;
  }
  if (data?.data?.length == 0) {
    return <></>;
  }
  return (
    <>
      <h5 className="mt-6">فایل های آپلود شده قبلی</h5>
      <div className="d-flex gap-3 flex-wrap mt-2">
        <table>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            {data.data.map((link) => {
              return (
                <tr href={link.file}>
                  <td>{timeSince(link.created_at)}</td>
                  <td className="fas fa-link ml-2"></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
