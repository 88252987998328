import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "./Hooks";

const Form = ({ options, edit = false, renderComponent = () => {} }) => {
  const { loadingEdit, submit, loading, renderElement, loadingNeed } = useForm({
    route: options.api ? options.api.route : "",
    needs: options.api ? options.api.needs : false,
    show: options.api.show || {},
    initial: options.api.initial || {},
    elements: options.form,
    sort:
      options.sort ||
      function (state) {
        return state;
      },
    sortInint:
      options.sortInint ||
      function (state) {
        return state;
      },
    edit,
    modalInit: options.initial || {},
    isModal: options.modal || false,
    customValidate: options.customValidate || false,
  });

  if (loadingEdit && edit) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <span
          class="spinner-border spinner-border"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
  }

  return (
    <div class={options.modal ? "" : "content"}>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              window.origin +
              "/assets/img/icons/spot-illustrations/corner-4.png",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3>{options.label}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {options &&
                  options.form.map(element => {
                    return renderElement(element);
                  })}
              </div>

              <button
                disabled={loading}
                onClick={submit}
                className="btn btn-primary mt-2 d-flex justify-content-center align-items-center "
              >
                {loading ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "ثبت"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {renderComponent ? <div className="row">{renderComponent()}</div> : <></>}
    </div>
  );
};

export default Form;
