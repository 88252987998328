import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiShow } from "react-icons/bi";
import { BsArrowLeftCircleFill, BsReply, BsSignpostSplitFill, BsToggles } from "react-icons/bs";
import { FaPhoenixFramework, FaTrash } from "react-icons/fa";
import { FiGift } from "react-icons/fi";
import { IoIosReturnLeft } from "react-icons/io";
import { MdModeEditOutline, MdPayment, MdTimer } from "react-icons/md";
import { TbAddressBook, TbFileInvoice } from "react-icons/tb";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMainContext } from "../../../Context";
import { useSearchObject } from "../../../Hooks";
import {
  cutText,
  renderPriceDash,
  renderStatus,
  timeSince,
  ToDateJalali,
  ToTimeJalali,
} from "../../../Utils/functions";

export const useTable = ({
  route = "",
  keyRoute = null,
  inint = {},
  selectAble = {},
  filters = [],
  dashboard = {},
  sortInint = () => { },
}) => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [LoadingDelete, setLoadingDelete] = useState(false);
  const [LoadingExcel, setLoadingExcel] = useState(false);
  const [areUSure, setAreUSure] = useState({
    id: null,
    status: false,
    data: {},
    type: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { findNestedObj } = useSearchObject();
  const { post, get, state, del } = useMainContext();
  const getData = async props => {
    if (keyRoute !== null) {
      setLoading(true);
      const { data } = await get(
        `${state.user.role}${route}?${keyRoute}=${id}&&${searchParams}`,
        {
          ...inint,
          ...props,
          ...createObjFilter(),
        }
      );
      setData(data || { data: [] });
      setLoading(false);
    } else {
      setLoading(true);
      const { data } = await get(`${state.user.role}${route}?${searchParams}`, {
        ...inint,
        ...props,
        ...createObjFilter(),
      });
      setData(data || { data: [] });
      setLoading(false);
    }
  };
  const createObjFilter = () => {
    let obj = {};
    filters.map(element => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    return obj;
  };
  const changeObj = (key, value) => {
    let obj = {};
    filters.map(element => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    setSearchParams({ ...obj, [key]: value });
  };
  const filterDelete = key => {
    let obj = {};
    filters.map(element => {
      if (element.key !== key)
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
    });
    setSearchParams({ ...obj });
  };
  const deleteRow = async (data, init = {}) => {
    setLoadingDelete(init.operation || true);
    if (data.type != "delete") {
      await post(
        `${state.user.role}${data.action.route}`,
        { [data?.action?.key]: data.id, ...init },
        true
      );
    } else {
      await del(`${state.user.role}${data.action.route}/${data.id}`, true);
    }
    setLoadingDelete(false);
    setSelected([]);
    setAreUSure({ status: false });
  };
  const navigate = useNavigate();

  const renderCloumn = (row, col) => {
    switch (col.type) {
      case "date":
        return row && timeSince(row[col.key]);
      case "long":
        return row && cutText(row[col.key]);
      case "status":
        return row && row[col.key] ? "active" : "deative";
      case "price":
        return row && row[col.key] ? renderPriceDash(row[col.key]) : 0;
      case "enum":
        return row && row[col.key] && col.enum[row[col.key]]
          ? col.enum[row[col.key]]
          : col.default;
      case "condition":
        return row && row[col.key] ? col.option.star : col.option.default;
      case "zero":
        return row && row[col.key] ? col.enum[0] : col.enum[1];
      case "cutText":
        return cutText(row[col.key], 50);
      case "nested":
        return row &&
          findNestedObj(row[col.objectKey], col.key) &&
          findNestedObj(row[col.objectKey], col.key)[col.key]
          ? findNestedObj(row[col.objectKey], col.key)[col.key]
          : "-------";
      case "exact-date":
        return ToTimeJalali(row[col.key]);
      case "multiLan":
        return ToDateJalali(row[col.key]);
      case "link":
        return (
          <a
            target="_blank"
            href={`https://api.ahantaraghi.com/${row[col.key]}`}
          >
            <span className="fas fa-file-excel"></span>
          </a>
        );
      case "static":
        return col.value;
      case "fullnameNested":
        return `${row?.author?.first_name} ${row?.author?.last_name}`;
      case "fullnameSeller":
        if (row.seller) {
          return `${row?.seller?.first_name} ${row?.seller?.last_name}`;
        } else {
          return `بدون تکنسین`;
        }
      case "fullname":
        if (row[col.key]?.first_name && row[col.key]?.last_name) {
          return `${row[col.key]?.first_name} ${row[col.key]?.last_name}`;
        } else {
          return `ثبت نشده`;
        }
      case "rel":
        if (row.rel) {
          return renderStatus(row?.rel?.status)["label"];
        } else {
          return "-----";
        }

      default:
        return row && row[col.key];
    }
  };
  const renderAction = (action, row) => {
    let props = {
      style: {
        marginRight: "10px",
      },
      color: "#a1a5b7",
    };
    switch (action.type) {
      case "edit":
        return (
          <Link
            to="/"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}>
            <MdModeEditOutline {...props} />
          </Link>
        );
      case "goNoFollow":
        let link = "";
        if (row.rel) {
          switch (row.product_id) {
            case 1:
            case "1":
              link = `https://thecartino.ir/c/${row.rel.key}`;
              break;
            case 2:
            case "2":
              link = `https://thecartino.ir/l/${row.rel.key}`;
              break;
            case 3:
            case "3":
              link = `https://thecartino.ir/f/${row.rel.key}`;
              break;
            default:
              break;
          }
        } else {
          link = false;
        }
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              window.open(link, "_blank");
            }}
            disabled={!link}>
            <BsArrowLeftCircleFill {...props} />
          </button>
        );
      case "show":
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={
              `${action.link}/${action.keyGo ? row[action.keyGo] : row.id}` ||
              "/"
            }>
            <BiShow {...props} />
          </Link>
        );
      case "show_w_query":
        let key = action.objectKey
          ? row &&
          findNestedObj(row[action.objectKey], action.key) &&
          findNestedObj(row[action.objectKey], action.key)[action.key]
          : action.keyGo
            ? row[action.keyGo]
            : row.id;
        let url = action.link + "?" + action.query + "=" + key;
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={url}>
            <BsArrowLeftCircleFill {...props} />
          </Link>
        );
      case "delete":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                type: "delete",
                action,
              });
            }}>
            <FaTrash {...props} />
          </button>
        );
      case "updateConfirm":
        return (
          <Link
            to={action.route}
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}>
            <AiOutlinePlus {...props} />
          </Link>
        );
      case "toggle":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "toggle",
              });
            }}>
            <BsToggles {...props} />
          </button>
        );
      case "toggleOrder":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "toggleOrder",
              });
            }}>
            <BsToggles {...props} />
          </button>
        );
      case "toggleAliOmid":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: {
                  ...row,
                  status: row.status == "new" ? "answered" : "new",
                },
                action,
                type: "toggleAliOmid",
              });
            }}>
            <BsToggles {...props} />
          </button>
        );
      case "gift":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "gift",
              });
            }}>
            <FiGift {...props} />
          </button>
        );
      case "select":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "select",
              });
            }}>
            <BsToggles {...props} />
          </button>
        );
      case "go":
        return <BsArrowLeftCircleFill {...props} />;
      case "payment":
        return <MdPayment {...props} />;
      case "time":
        return <MdTimer {...props} />;
      case "add":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "add",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}>
            <AiOutlinePlus {...props} />
          </button>
        );
      case "reply":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "reply",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}>
            <BsReply {...props} />{" "}
          </button>
        );
      case "report":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "report",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}>
            <AiOutlinePlus {...props} />
          </button>
        );
      case "aliOmid":
        return (
          <button
            className="btn btn-danger fs--1"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate(action.link || "/careers/records", { state: { row } });
            }}>
            {action.text || "مشاهده فرم های پرشده"}
          </button>
        );
      case "addFrame":
        return (
          <>
            <button
              data-toggle="tooltip"
              data-placement="top"
              title={action.label}
              disabled={
                row.rel
                  ? row.rel.status == "receiving_info"
                    ? false
                    : true
                  : true
              }
              onClick={() => {
                navigate("/order/submitOrder/" + row.id);
              }}
              className=" p-0 m-0"
              style={{ border: "none", backgroundColor: "transparent" }}>
              <FaPhoenixFramework {...props} />
            </button>
          </>
        );
      case "trackingPost":
        return (
          <>
            <button
              data-toggle="tooltip"
              data-placement="top"
              title={action.label}
              disabled={
                row.rel
                  ? row.rel.status == "ready_to_send"
                    ? false
                    : true
                  : true
              }
              onClick={() => {
                setAreUSure({
                  status: true,
                  id: row.id,
                  data: row,
                  type: "trackingPost",
                  action,
                });
              }}
              className=" p-0 m-0"
              style={{ border: "none", backgroundColor: "transparent" }}>
              <BsSignpostSplitFill {...props} />
            </button>
          </>
        );
      case "update_price":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "update_price",
              });
            }}
          >
            <MdPayment {...props} />
          </button>
        );
      case "address":
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={
              `${action.link}/${action.keyGo ? row[action.keyGo] : row.id}` ||
              "/"
            }
          >
            <TbAddressBook {...props} />
          </Link>
        );

      case "newOrder":
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={`/order/${row.id}`}
          >
            <TbFileInvoice {...props} />
          </Link>
        );

      default:
        break;
    }
  };
  const isIn = id => {
    return selected.includes(id);
  };
  const toggle = id => {
    if (isIn(id)) {
      setSelected([...selected.filter(item => item !== id)]);
    } else {
      setSelected([...selected, id]);
    }
  };
  const toggleAll = () => {
    if (isSelectedAll()) {
      setSelected([]);
    } else {
      setSelected([...data.data.map(item => item.id)]);
    }
  };
  const isSelectedAll = () => {
    return selected.length == data?.data.length;
  };
  const length = () => {
    return selected.length;
  };

  return {
    renderCloumn,
    loading,
    getData,
    data,
    renderAction,
    areUSure,
    setAreUSure,
    LoadingDelete,
    deleteRow,
    isIn,
    toggle,
    toggleAll,
    isSelectedAll,
    length,
    selected,
    searchParams,
    setSearchParams,
    changeObj,
    filterDelete,
  };
};
