import React from "react";
import "./post-label.css";
import * as htmlToImage from "html-to-image";
import { saveAs } from "../../Utils/functions";

function PostalCode({
  receiverName,
  receiverAddress,
  receiverCode,
  receiverPhone,
  receiptNumber,
  orderDate,
  printDate,
  post_tracking_code,
}) {
  const printKir = () => {
    var data = document.getElementsByClassName("htmlToImageVis");

    for (var i = 0; i < data.length; ++i) {
      htmlToImage.toPng(data[i]).then(dataUrl => {
        saveAs(dataUrl, "my-node.png");
      });
    }
  };
  return (
    <>
      {" "}
      <h5 className="mb-4">کارت پستی</h5>
      {post_tracking_code && (
        <div className="my-3">کد پستی : {post_tracking_code}</div>
      )}
      <div className="htmlToImageVis post-label d-flex flex-column justify-content-between pt-px-30 pr-px-40 pl-px-40">
        <div className=" d-flex flex-wrap flex-md-nowrap flex-grow-1 ">
          <div className="post-label-receiver pl-px-40  flex-grow-1 ">
            <div className="d-flex flex-column justify-content-start  h-100">
              <div className=" flex-grow-1 "></div>

              <div className="fw-600">
                <h4 className="fw-700">گیرنده</h4>
                <p className="fw-600 mb-0 pb-0">{receiverAddress}</p>

                <div className="fw-600 mt-3">
                  <span>کد پستی :</span>
                  <span>{receiverCode}</span>
                </div>
                <div className="fw-600 mt-3">
                  <span>نام کامل گیرنده :</span>
                  <span>{receiverName}</span>
                </div>
                <div className="fw-600 mt-3">
                  <span> تلفن گیرنده :</span>
                  <span>{receiverPhone}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="post-label-sender pr-px-40 flex-grow-1">
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <h4 className="fw-700">مجموعه کارتینو</h4>
                <p className="fw-600 mb-0">
                  تهران | خیابان قصرالدشت | حدفاصل هاشمی و امام خمینی | کوچه
                  هیزمی آرانی | پلاک ۲۶ | واحد ۱
                </p>

                <div className="fw-600 mt-3">کد پستی: 1346815415</div>
                <div className="fw-600 mt-3">تلفن گیرنده: 02191307530</div>
                <div className="fw-600 mt-3">وب‌سایت: thecartino.ir</div>
                <div className="fw-600 mt-3">ایمیل: Info@thecartino.ir</div>
              </div>
              <div className="d-flex justify-content-end mb-px-10 post-label-logo">
                <img src={require("./logo-dark.svg").default} />
              </div>
            </div>
          </div>
        </div>
        <div className="post-label-footer d-flex align-items-center justify-content-evenly flex-wrap mt-px-30 text-center fw-600">
          <div className="d-flex">
            <span className="d-inline-block ml-px-10">شماره سفارش:</span>
            <span>{receiptNumber}</span>
          </div>
          <div className="d-flex">
            <span className="d-inline-block ml-px-10">تاریخ ثبت سفارش:</span>
            <span>{orderDate}</span>
          </div>
          <div className="d-flex">
            <span className="d-inline-block ml-px-10">تاریخ ارسال سفارش:</span>
            <span>{printDate}</span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <button className="btn btn-danger" onClick={printKir}>
          دانلود تصویر
        </button>
      </div>
    </>
  );
}

export default PostalCode;
