import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useMainContext } from "../../Context";
import { stracture } from "../Panel";

const Sidebar = () => {
  const { pathname } = useLocation();
  const {
    logout,
    state: { user },
  } = useMainContext();
  return (
    <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
      <div className="d-flex align-items-center">
        <a className="navbar-brand">
          <div className="d-flex align-items-center flex-column justify-content-center py-3">
            <img
              className="me-2 header-logo"
              src={window.origin + "/assets/img/logo-light.svg"}
              alt
              width={100}
              height={100}
            />
            <span
              className="font-sans-serif"
              style={{
                fontSize: "17px",
                marginRight: "5px",
                color: "#fff",
                color: "#F26921",
              }}
            >
              کارتینو
            </span>
            <p className="fs--1 text-dark">سامانه جامع آنلاین</p>
          </div>
        </a>
      </div>
      <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div className="navbar-vertical-content scrollbar" style={{ paddingBottom: '150px' }}>
          <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            {stracture.pages &&
              stracture.pages.map((page, count) => {
                if (page.access && page.access.includes(user.role || "admin")) {
                  if (page.nested) {
                    return (
                      <li className="nav-item mb-2">
                        <a
                          className="nav-link dropdown-indicator collapsed"
                          href={`#da-${count}`}
                          role="button"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-controls={`da-${count}`}
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-icon">
                              <i className={page.icon} />
                              {/* <span class="fas fa-chart-pie"></span> Font Awesome fontawesome.com */}
                            </span>
                            <span className="nav-link-text fs--0  ps-1">
                              {page.label}
                            </span>
                          </div>
                        </a>
                        <ul className="nav collapse " id={`da-${count}`}>
                          {page.options.map(option => {
                            if (option.show) {
                              return (
                                <li className="nav-item">
                                  <Link
                                    className={` nav-link ${option.route == pathname && "active"
                                      }`}
                                    to={option.route}
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span className="nav-link-text ps-1">
                                        {option.label}
                                      </span>
                                    </div>
                                  </Link>
                                  {/* more inner pages*/}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <Link
                        class="nav-link mb-2"
                        to={page.route}
                        role="button"
                        aria-expanded="false"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-icon">
                            <i className={page.icon} />
                          </span>
                          <span class="nav-link-text ps-1">{page.label}</span>
                        </div>{" "}
                      </Link>
                    );
                  }
                }
              })}

            <a
              onClick={logout}
              class="nav-link"
              role="button"
              aria-expanded="false"
            >
              <div class="d-flex align-items-center">
                <span class="nav-link-icon">
                  <i />
                </span>
                <span class="nav-link-text ps-1">خروج</span>
              </div>{" "}
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
