import { useEffect, useState } from "react";
import { useMainContext } from "../../../Context";

export const useProfile = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ get: true, send: false });
  const {
    state: { user },
    post,
  } = useMainContext();
  const getData = async () => {
    setLoading({ get: true });
    const { data } = await post(`${user.role}/profile`);
    setData(data);
    setLoading({ get: false });
  };
  useEffect(() => {
    getData();
  }, []);
  const obj = { admin: "ادمین", operator: "کارشناس", owner: "ادمین" };
  const renderRole = role => {
    if (role) {
      return obj[role];
    } else {
      return "ثبت نشده";
    }
  };
  const sendData = async type => {
    setLoading({ send: "edit_profile" });
    await post(
      `${user.role}/profile/edit`,
      { ...data, type, mobile: false },
      true
    );
    setLoading({ send: false });
  };
  const changePassword = async type => {
    setLoading({ send: "password" });
    await post(
      `${user.role}/password/change`,
      { ...data, type, mobile: false },
      true
    );
    setLoading({ send: false });
  };
  const updateImage = async photo => {
    setLoading({ update: true });
    await post(
      `${user.role}/profile/edit`,
      { type: "change_photo", photo },
      true
    );
    setLoading({ update: false });
    getData();
  };
  return {
    setData,
    data,
    loading,
    renderRole,
    sendData,
    updateImage,
    changePassword,
  };
};
