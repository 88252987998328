import React from "react";
import { useLocation } from "react-router-dom";
import { timeSince, ToTimeJalali } from "../../Utils/functions";
import OrderDetails from "./OrderDetails";
import Reports from "./Reports";
import TicketDetails from "./ticketDetails";
import Profile from "../Profile";

const Surfaces = ({ option }) => {
  const { state } = useLocation();
  switch (option.surfaceType) {
    case "orderDetails":
      return <OrderDetails option={option} />;
    case "reports":
      return <Reports option={option} />;
    case "ticket":
      return (
        <>
          <TicketDetails />
        </>
      );
    case "aliOimd":
      return <></>;
    case "profile":
      return <Profile />;
    default:
      break;
  }
  return (
    <div>
      <>
        <div className="col-12 ">
          <div className="card h-100">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <img
                  className="me-2"
                  src="../assets/img/icons/signal.png"
                  alt
                  height={35}
                />
                <h5 className="fs-0 fw-normal text-800 mb-0">
                  فرم های ارسال شده : {state?.row?.title?.fa}
                </h5>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="s">
                {state?.row?.forms?.map(item => {
                  return (
                    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
                      <div className="d-flex align-items-center mb-3">
                        <span className="fas fa-code-branch text-primary" />
                        <a
                          className="stretched- text-decoration-none"
                          href={item.file}
                        >
                          <h5 className="fs--3 text-800 mb-0 ps-3">
                            {item.fullname}
                          </h5>
                        </a>
                      </div>
                      <div className="d-flex justify-content- align-items-center">
                        <h5 className="fs--1 text-600">تحصیلات : </h5>
                        <h5 className="fs--1 text-800 mx-2">
                          {`   ${item.education}  `}
                        </h5>
                      </div>{" "}
                      <div className="d-flex justify-content- align-items-center  my-1">
                        <h5 className="fs--1 text-600">سن : </h5>
                        <h5 className="fs--1 text-800 mx-2">
                          {`   ${item.age}  `}
                        </h5>
                      </div>{" "}
                      <div className="d-flex justify-content- align-items-center my-1">
                        <h5 className="fs--1 text-600">شماره تماس : </h5>
                        <h5 className="fs--1 text-800 mx-2">
                          {`   ${item.telephone}  `}
                        </h5>
                      </div>
                      <div className="d-flex justify-content- align-items-center  my-1">
                        <h5 className="fs--1 text-600"> آدرس : </h5>
                        <h5 className="fs--1 text-800 mx-2">
                          {`   ${item.address}  `}
                        </h5>
                      </div>
                      <div className="d-flex justify-content- align-items-center my-1">
                        <h5 className="fs--1 text-600"> ایمیل : </h5>
                        <h5 className="fs--1 text-800 mx-2">
                          {`   ${item.email}  `}
                        </h5>
                      </div>
                      <div className="d-flex justify-content- align-items-center my-1">
                        <h5 className="fs--1 text-600"> وضعیت تاهل : </h5>
                        <h5 className="fs--1 text-800 mx-2">
                          {`   ${
                            item.relationship == "0" ? "مجرد" : "متاهل"
                          }   `}
                        </h5>
                      </div>
                      <div className="d-flex justify-content- align-items-center my-1">
                        <h5 className="fs--1 text-600"> زمان ثبت : </h5>
                        <h5 className="fs--1 text-800 mx-2">
                          {`   ${ToTimeJalali(item.created_at)}  `}
                        </h5>
                      </div>
                      <div className="d-flex justify-content- align-items-center mt-4">
                        {item.file && (
                          <a
                            target="_blank"
                            className="btn btn-danger"
                            href={`https://api.ahantaraghi.com/${item.file}`}
                          >
                            فایل رزومه
                          </a>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Surfaces;
