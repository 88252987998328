import React from "react";
import { useProfile } from "./Hooks";

const Profile = () => {
  const {
    updateImage,
    sendData,
    data,
    loading,
    renderRole,
    setData,
    changePassword,
  } = useProfile();

  if (loading.get) {
    return (
      <div className="hv-100 d-flex align-items-center justify-content-center">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
  }
  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-12">
          <div className="card mb-3 btn-reveal-trigger">
            <div className="card-header position-relative min-vh-25 mb-8">
              <div className="cover-image">
                <div
                  className="bg-holder rounded-3 rounded-bottom-0"
                  style={{
                    backgroundImage: "url(../../assets/img/chat/6.jpg)",
                  }}
                />
                {/*/.bg-holder*/}
              </div>
              <div className="avatar avatar-5xl avatar-profile shadow-sm img-thumbnail rounded-circle">
                {loading.update ? (
                  <div
                    style={{ zIndex: 114124124124 }}
                    className="h-100 w-100 d-flex align-items-center justify-content-center rounded-circle overflow-hidden position-relative"
                  >
                    <span
                      class="spinner-border spinner-border"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  <>
                    <div className="h-100 w-100 rounded-circle overflow-hidden position-relative">
                      {" "}
                      <img
                        src={
                          data.photo && data.photo.length > 0
                            ? data.photo[0].url
                            : "../../assets/img/team/avatar.png"
                        }
                        width={200}
                        alt
                        data-dz-thumbnail="data-dz-thumbnail"
                      />
                      <input
                        className="d-none"
                        id="profile-image"
                        type="file"
                        onChange={({ target: { files } }) => {
                          updateImage(files[0]);
                        }}
                      />
                      <label
                        className="mb-0 overlay-icon d-flex flex-center"
                        htmlFor="profile-image"
                      >
                        <span className="bg-holder overlay overlay-0" />
                        <span className="z-index-1 text-white dark__text-white text-center fs--1">
                          <svg
                            className="svg-inline--fa fa-camera fa-w-16"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="camera"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg
                          >
                            <path
                              fill="currentColor"
                              d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z"
                            />
                          </svg>
                          {/* <span class="fas fa-camera"></span> Font Awesome fontawesome.com */}
                          <span className="d-block">به روز رسانی</span>
                        </span>
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 pe-lg-2">
          <div className="card mb-3">
            <div className="card-header">
              <h5 className="mb-0">تنظیمات پروفایل</h5>
            </div>
            <div className="card-body bg-light">
              <div className="row g-3">
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="first-name">
                    نام{" "}
                  </label>
                  <input
                    className="form-control"
                    id="first-name"
                    type="text"
                    value={data.first_name}
                    onChange={({ target: { value } }) => {
                      setData({ ...data, first_name: value });
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="first-name">
                    نام خانوادگی
                  </label>
                  <input
                    className="form-control"
                    id="first-name"
                    type="text"
                    value={data.last_name}
                    onChange={({ target: { value } }) => {
                      setData({ ...data, last_name: value });
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="email2">
                    کد ملی
                  </label>
                  <input
                    className="form-control"
                    id="email2"
                    type="text"
                    value={data.national_id}
                    onChange={({ target: { value } }) => {
                      setData({ ...data, national_id: value });
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="last-name">
                    ایمیل
                  </label>
                  <input
                    className="form-control"
                    id="last-name"
                    type="text"
                    value={data.email}
                    onChange={({ target: { value } }) => {
                      setData({ ...data, email: value });
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label" htmlFor="email3">
                    نقش
                  </label>
                  <input
                    className="form-control"
                    id="email3"
                    type="text"
                    disabled
                    value={renderRole(data.role)}
                  />
                </div>{" "}
                <div className="col-12 d-flex justify-content-end">
                  <button
                    onClick={() => {
                      sendData("edit_profile");
                    }}
                    className="btn btn-primary"
                    type="submit"
                    loading={loading.send}
                  >
                    {loading.send == "edit_profile" ? (
                      <>
                        <span
                          class="spinner-border spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      <>به روز رسانی</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">تغییر رمز عبور</h5>
            </div>
            <div className="card-body bg-light">
              <div className="row g-3">
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="first-name">
                    رمز قبلی
                  </label>
                  <input
                    className="form-control"
                    id="first-name"
                    type="password"
                    onChange={({ target: { value } }) => {
                      setData({ ...data, old_password: value });
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="email2">
                    رمز جدید
                  </label>
                  <input
                    className="form-control"
                    id="email2"
                    type="password"
                    onChange={({ target: { value } }) => {
                      setData({ ...data, new_password: value });
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <button
                    onClick={() => {
                      changePassword("change_password");
                    }}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {loading.send == "change_password" ? (
                      <>
                        <span
                          class="spinner-border spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      <>ویرایش رمز عبور</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
