import { post } from "../Server";

export const useSearchObject = () => {
  const findNestedObj = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind]) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };
  return {
    findNestedObj,
  };
};

export const logOut = () => {
  const logout = async () => {
    const { data, status } = await post("/logout");
    if (+status === 200) {
      localStorage.removeItem("user");
      window.location.replace("/");
    }
  };
  return { logout };
};
